import { Box, Typography } from '@mui/material';
import { data } from '../../utils/data';
import { Reason8 } from './style';
import Anchor from '../../components/Anchor';
import { useEffect, useRef } from 'react';

import { ReactComponent as Coin } from '../../res/coin.svg';

function B08economicpower(props) {
  const { gsap } = props;
  const base = data[props.lang].b08;
  const reason8 = useRef(null);

  useEffect(() => {

    gsap.to(".coin", {
      duration: 2,
      y: '150vh',
      ease: "power1.inOut",
      scrub: 1,
      stagger: {
        from: "random",
        amount: 1.5,
        repeat: -1,
      }
    });
    gsap.to(".coin0", {
      duration: 4,
      y: '150vh',
      ease: "power1.inOut",
      scrub: 1,
      stagger: {
        from: "random",
        amount: 1.5,
        repeat: -1,
      }
    });

  }, [gsap]);

  return (
    <Reason8 ref={reason8}>
      <Anchor id='8' />
      <Box className='fade'></Box>
      <Box className='wrapper'>
        <Typography variant="body1" className="title1" sx={{marginBottom: {xs: 0, md: 4}}}>{base.title1}</Typography>
        <Typography variant="body1" className="cadre title2">{base.title2}</Typography>
        <Typography variant="body1" className="cadre title3">{base.title3}</Typography>
      </Box>
      <Box className='coins'>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin0'/>
        <Coin className='coin'/>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin0'/>
        <Coin className='coin'/>
        <Coin className='coin'/>
        <Coin className='coin'/>
        <Coin className='coin0'/>
        <Coin className='coin0'/>
      </Box>
      </Reason8>
  );
}

export default B08economicpower;