import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason5 = styled(Box)(
  ({ theme }) => `
  height: 150vh;
  background-color: ${theme.palette.pink.main};
  .title {
    padding-top: ${theme.spacing(16)};
    padding-bottom: ${theme.spacing(16)};
  }
  .title1 {
    width: fit-content;
    padding-bottom: ${theme.spacing(1)};
    font-weight: 500;
    color: ${theme.palette.white.main};
  }
  .bluetitle {
    position: relative;
    width: fit-content;
    padding: ${theme.spacing(1)};
    transform: rotate(1.5deg);
    font-family: 'vFraunces';
    font-weight: 600;
    background-color: ${theme.palette.blue.light};
    color: ${theme.palette.blue.main};

    background:
      radial-gradient(circle 10px at top left , #0000 98% , ${theme.palette.blue.light}  ) top left,
      radial-gradient(circle 10px at top right , #0000 98% , ${theme.palette.blue.light}  ) top right,
      radial-gradient(circle 10px at bottom right , #0000 98% , ${theme.palette.blue.light}  ) bottom right,
      radial-gradient(circle 10px at bottom left , #0000 98% , ${theme.palette.blue.light}  ) bottom left;
    background-size:51% 51%;
    background-repeat:no-repeat;
  }
  .corner {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: ${theme.palette.pink.main};
    transform: translate(-50%, -50%);
    border-radius: 10px;
  }
  .corner1 {top:0;left:0}
  .corner2 {top:0;left:100%}
  .corner3 {top:100%;left:0}
  .corner4 {top:100%;left:100%}
  .content {
    position: relative;
    height: 100vh;
  }
  .star {
    width: min(90vw, 400px);
    animation: rotation 40s linear infinite;
  }
  .wrapper {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .feature {
    position: relative;
    width: 100vw;
    height: 150px;
    opacity: 0;
  }
  .feature-bg{
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    aspect-ratio: 1;
    background-color: ${theme.palette.yellow.main};
    border-radius: 50%;
  }
  .feature-title, .feature-title-2 {
    text-transform: initial;
    text-align: center;
  }
   .feature-title-2 {
    font-size: 28px;
    line-height: 1.2;
    max-width: 360px;
  }
  .feature-desc {
    position: absolute;
    width: min(70vw, 300px);
    text-align: center;
    top: 120px;
    opacity: 0;
    transform: scale(0.5);
  }

  @keyframes rotation{
    0% { transform: rotate(0deg)}
    100% { transform: rotate(360deg)}
  }
`);