import { Dialog } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)(
  ({ theme }) => `
  position: relative;

  &.bg-orange .MuiPaper-root {background-color: ${theme.palette.orange.main};}
  &.bg-blue .MuiPaper-root {background-color: ${theme.palette.blue.main};}
  &.bg-green .MuiPaper-root {background-color: ${theme.palette.green.main};}
  &.bg-teal .MuiPaper-root {background-color: ${theme.palette.teal.main};}
  &.bg-pink .MuiPaper-root {background-color: ${theme.palette.pink.main};}
  &.bg-bluelight .MuiPaper-root {background-color: ${theme.palette.blue.light};}

  &.orange .btn {background-color: ${theme.palette.orange.main};color: ${theme.palette.orange.contrastText}} 
  &.blue .btn {background-color: ${theme.palette.blue.light};color: ${theme.palette.blue.contrastText}} 
  &.green .btn {background-color: ${theme.palette.green.main};color: ${theme.palette.green.contrastText}}
  &.teal .btn {background-color: ${theme.palette.teal.main};color: ${theme.palette.teal.contrastText}}
  &.pink .btn {background-color: ${theme.palette.pink.main};color: ${theme.palette.pink.contrastText}}
  &.bluelight .btn {background-color: ${theme.palette.blue.light};color: ${theme.palette.blue.contrastText}}
  
  .MuiPaper-root {
    position: relative;
    border-radius: 30px;
    width: min(100vw, 500px);
    height: min(70vh, 800px);
    padding-top: ${theme.spacing(12)};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    overflow: initial;

    &:after {
      pointer-event: none;
      content: '';
      position: absolute;
      width: 100%;
      height: 40px;
      display: block;
      background-color: grey;
      bottom: 0px;
      left: 0;
      border-radius: 0 0 30px 30px;
      background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    }
  }

  &.bg-orange .MuiPaper-root:after {background: linear-gradient(to top, ${theme.palette.orange.main} 0%, ${theme.palette.orange.main} 50%, rgba(255,255,255,0) 100%)} 
  &.bg-blue .MuiPaper-root:after {background: linear-gradient(to top, ${theme.palette.blue.main} 0%, ${theme.palette.blue.main} 50%, rgba(255,255,255,0) 100%)} 
  &.bg-green .MuiPaper-root:after {background: linear-gradient(to top, ${theme.palette.green.main} 0%, ${theme.palette.green.main} 50%, rgba(255,255,255,0) 100%)} 
  &.bg-teal .MuiPaper-root:after {background: linear-gradient(to top, ${theme.palette.teal.main} 0%, ${theme.palette.teal.main} 50%, rgba(255,255,255,0) 100%)} 
  &.bg-pink .MuiPaper-root:after {background: linear-gradient(to top, ${theme.palette.pink.main} 0%, ${theme.palette.pink.main} 50%, rgba(255,255,255,0) 100%)} 
  &.bg-bluelight .MuiPaper-root:after {background: linear-gradient(to top, ${theme.palette.blue.light} 0%, ${theme.palette.blue.light} 50%, rgba(255,255,255,0) 100%)} 


  .content {
    padding-bottom: 50px;
  }

  .border, 
  .snake {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
  }

  .border {
    border: solid 1px ${theme.palette.white.main};
    border-radius: 15px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
  }

  .title {
    color: ${theme.palette.white.main};
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 900;
    letter-spacing: 2px;
  }
  
  .country {
    color: ${theme.palette.yellow.main};
  }
  
  .building {
    margin-top: ${theme.spacing(2)};
    font-weight: 700;
    color: ${theme.palette.yellow.main};
  }
  
  .desc {
    color: ${theme.palette.white.main};
  }

  .pics {
    position: absolute;
    top: -20%;
    left: 50%;
    transform: translate(-50%);
    width: calc(100% - 120px);
    aspect-ratio: 1;
    max-width: 210px;
    max-height: 210px;
    z-index: 3;
  }

  .pic,
  .macaron,
  .calc,
  .btn {
    position: absolute;
    z-index: 3;
  }

  .pic {
    width: 100%;
    max-width: 200px;
    left: 50%;
    transform: translateX(-50%);
  }
  .macaron {
    width: 70%;
    animation: rotation 40s infinite;
  }
  .calc {
    width: 30%;
    bottom: 0;
    right: 0;
  }
  .btn {
    width: min(70%, 300px);
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    border-radius: 50px;
    text-align: center;
  }
  .close {
    fill: ${theme.palette.white.main};
    position: absolute;
    top: -50px;
    right: 0;
    pointer-events: none;
  }
`);