import { Box, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import Anchor from '../../components/Anchor';

import parse from '../../utils/parse';
import { data } from '../../utils/data';

import { ReactComponent as Cursor } from '../../res/cursor.svg';
import { ReactComponent as Bag } from '../../res/bag.svg';
import { ReactComponent as Planet } from '../../res/planet.svg';

import { Reason4 } from './style';

function getFinalLetter(data, row, col) {
  return data[row][col];
}

function B04french(props) {
  const { gsap, lang } = props;
  const reason4 = useRef(null);

  const base = data[lang].b04french;

  const [letters, setLetters] = useState([]);
  const [stopLetters, setStopLetters] = useState(0);

  useEffect(() => {
    const elt = reason4.current;

    const startStop = gsap.timeline({
      scrollTrigger: {
        trigger: elt.querySelector('.pinWrapper'),
        start: `top ${window.screen.height * 0.2}px`,
        end: () => `${elt.offsetHeight - 75}px bottom`,
        pin: true,
      }
    })
    startStop.add(() => {
      setStopLetters(1);
    });
  }, [gsap]);


  useEffect(() => {
    function matrix() {
      const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
      const tmpLetters = [...letters];
      let tmpListLetter;
      for (let row = 0; row < base.final.length; row++) {
        if (tmpLetters[row] === undefined) {
          tmpLetters[row] = [];
        } else {
          tmpLetters[row] = [...tmpLetters[row]];
        }
        for (let column = 0; column < base.final[row].length; column++) {
          const fl = getFinalLetter(base.final, row, column);
          if (!stopLetters || tmpLetters[row][column] !== fl) {
            tmpListLetter = [...alphabet];
            if (stopLetters) {
              tmpListLetter.push(fl, fl, fl, fl);
            }
            if (stopLetters > 8) {
              tmpLetters[row][column] = fl;
            } else {
              const randomCharacter = tmpListLetter[Math.floor(Math.random() * tmpListLetter.length)];
              tmpLetters[row][column] = randomCharacter;
            }
          }
        }
      };
      setLetters(tmpLetters);
      if (stopLetters > 0) {
        setStopLetters(stopLetters + 1);
      }
    }
    const interval = setTimeout(() => matrix(), 40);
    return () => clearTimeout(interval);
  }, [letters, base, stopLetters]);

  return (
    <Reason4 ref={reason4} justifyContent='center' alignItems='center'>
      <Anchor id='4' />
      <Box className='pinWrapper'>
        <Box variant="body1" className="desc anim-screen6">
          {letters.map((row, i) =>
          (
            <Box className='row' key={i}>
              {row.map((letter, j) => (
                <Typography fontFamily='SilkaMono' className='letter' key={j}>{letter}</Typography>
              ))}
            </Box>
          )
          )}
        </Box>
      </Box>
      <Box variant="body1" className="languages">
        <Box className='container container-planet'>
          <Box className='wrapper wrapper-planet'>
            <Typography className='title'>{parse(base.title1)}</Typography>
            <Typography className='subtitle'>{base.desc1}</Typography>
          </Box>
          <Planet className='planet' />
        </Box>
        <Box className='container container-bag'>
          <Box className='wrapper wrapper-bag'>
            <Typography className='title'>{parse(base.title2)}</Typography>
            <Typography className='subtitle'>{base.desc2}</Typography>
            <Bag className='bag' />
          </Box>
        </Box>
        <Box className='container container-cursor'>
          <Box className='wrapper wrapper-cursor'>
            <Typography className='title'>{parse(base.title3)}</Typography>
            <Typography className='subtitle'>{base.desc3}</Typography>
            <Cursor className='cursor' />
          </Box>
        </Box>
      </Box>
    </Reason4>

  );
}

export default B04french;