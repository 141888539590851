import { Box } from '@mui/material';
import styled from 'styled-components';

export const StyledAnchor = styled(Box)(
  ({ theme }) => `
  position: relative;
  width: 100%;
  pointerEvents: none;
  height: 0;
  left: 0;
`);