import { Box } from '@mui/material';
import styled from 'styled-components';

export const Menu = styled(Box)(
  ({ theme }) => `
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;
  background-color: ${theme.palette.white.pink};

  .item {
    flex: 1;
    height: 30px;
    margin: 0 1px;
    color: ${theme.palette.black.main};
    background-color: ${theme.palette.white.main};
    cursor: pointer;
  };
  .active {
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.blue.main};
  };
`);