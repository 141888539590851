import { useRef, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { data } from '../../utils/data';
import { Reason2 } from './style';
import Anchor from '../../components/Anchor';

import { ReactComponent as Hat } from '../../res/hat.svg';
import { ReactComponent as Map } from '../../res/longmap2.svg';
import { ReactComponent as Pin } from '../../res/pin.svg';


function B02rendezvous(props) {
  const { gsap, lang } = props;

  const base = data[lang].b02;

  const ref = useRef(null);

  useEffect(() => {
    const elt = ref.current;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top 70%',
        end: 'top top',
        scrub: 1,
      }
    });

    tl.from(elt.querySelector('.anim-title1'), { opacity: 0, x: '100vw', duration: 20, ease: 'linear' }, 5)
      .from(elt.querySelector('.anim-title2'), { opacity: 0, x: '-100vw', duration: 20, ease: 'linear' }, 10)
      .from(elt.querySelector('.anim-frame'), { opacity: 0, scale: 0, duration: 20, ease: 'linear' }, 15)
      .from(elt.querySelector('.anim-hat'), { opacity: 0, scale: 0, duration: 20, ease: 'linear' }, 15)
      .from(elt.querySelector('.anim-disk'), { opacity: 0, scale: 0, duration: 20, ease: 'linear' }, 15)
      .from(elt.querySelector('.anim-map'), { x: 400, duration: 50, ease: 'linear' }, 30)
      .from(elt.querySelector('.anim-pin'), { opacity: 0, duration: 20, ease: 'linear' }, 80);


    gsap.to(
      elt.querySelector('.anim-pin'),
      {
        scrollTrigger: {
          trigger: elt,
          start: 'top top',
          pin: true,
          end: () => `+=${window.screen.height / 2}px`,
          scrub: 1,
        },
        height: '150vh',
        top: '-50vh',
        ease: 'linear'
      }
    );
  }, [gsap]);

  return (
    <Reason2 ref={ref}>
      <Anchor id='2' />
      <Box className='wrapper'>
        <Box className='title-wrapper'>
          {lang === 'fr' ? (
            <>
              <Typography variant="h4" className="title anim-title1">{base.title1}</Typography>
              <Typography variant="h4" className="title anim-title2">{base.title2}</Typography>
              <Hat className='hat anim-hat' />
              <Box className="frame anim-frame">
                <Typography variant="h4" className="subtitle">{base.title3}</Typography>
                <Box className='corner corner1'></Box>
                <Box className='corner corner2'></Box>
              </Box>
            </>
          ) : (
            <>
              <Box className="frame anim-frame">
                <Typography textTransform='none' variant="h4" className="subtitle">{base.title1}</Typography>
                <Box className='corner corner1'></Box>
                <Box className='corner corner2'></Box>
              </Box>
              <Typography variant="h4" className="title anim-title1">{base.title2}</Typography>
              <Typography variant="h4" className="title anim-title2">{base.title3}</Typography>
              <Hat className='hat anim-hat' />
            </>
          )}
        </Box>
      </Box>
      <Box className='wrapper2'>
        <Box className='disk anim-disk'>
          <Map className='map anim-map' />
        </Box>
        <Pin className='pin anim-pin' />
      </Box>
    </Reason2>
  );
}

export default B02rendezvous;