import { Box } from '@mui/material';
import styled from 'styled-components';

export const AppContainer = styled(Box)(
  ({ theme }) => `
  overflow-x: clip;
  .pin-spacer{
    margin-top: -1px !important;
    margin-bottom: -1px !important;
  }
`);