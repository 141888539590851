import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason10 = styled(Box)(
  ({ theme }) => `
  position: relative;
  background-color: ${theme.palette.yellow.main};
  padding: ${theme.spacing(8)};
  height: 200vh;
  .title {
    position: absolute;
    top: 10px;
    left: 100%;
    color: ${theme.palette.orange.main};
    white-space: nowrap;
    z-index: 10;
  }
  .illustrations {
    position: relative;
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  .illus {
    position: absolute;
    z-index: 1;
    max-height:  min(30vh, 400px);
    max-width: min(60vw, 400px);
  }
  .small {
    max-height:  min(20vh, 200px);
    max-width: min(40vw, 200px);
  }
  .illus1 {
    top: 150px;
    left: -10vw;
    z-index: 2;
  }
  .illus2 {
    top: 250px;
    right: -10vw;
  }
  .illus3 {
    z-index: 1;
    top: 70vh;
    left: 0;
  }
  .stamp {
    position: absolute;
    z-index: 3;
    top: calc(100vh + 50px);
    left: 25%;
  }
  .illus4 {
    top: 105vh;
    left: 40%;
  }
  .stamp-tower {
    position: absolute;
    top: 90vh;
    right: 5%;
  }
  .illus5 {
    top: 138vh;
    left: -14vw;
  }
  .illus6 {
    top: 155vh;
    right: -15vw;
  }
`);