import { StyledButton } from './style';

function Button(props) {

  return (
    <StyledButton onClick={props.onClick}>{props.children}</StyledButton>
  );
}

export default Button;
