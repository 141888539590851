import { Box } from '@mui/material';
import styled from 'styled-components';

export const Cursus13 = styled(Box)(
  ({ theme }) => `
  position: relative;
  z-index: 1;
  background-color: ${theme.palette.blue.light};
  padding-top: ${theme.spacing(4)};
  .content {
    position: relative;
    max-width: 600px;
    margin: auto;
    padding-bottom: ${theme.spacing(12)};
  }
  .title {
    position: absolute;
    width: 50%;
    height: calc(100% - ${theme.spacing(12) });
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
    color: ${theme.palette.white.main};
  }
  .sticky {
    position: sticky;
    top: ${theme.spacing(4)};
  }
  .cursus {
    position: relative;
    left: 50%;
    width: 50%;
    margin-bottom: ${theme.spacing(12)};
    color: ${theme.palette.white.main};
    padding-left: ${theme.spacing(2)};
    padding-right: ${theme.spacing(2)};
  }
  .item {
    height: 30px;
  }
  .cta {
    position: relative;
    height: 200px;
    background-color: ${theme.palette.yellow.main};
    &-content {
      width: min(80vw, 250px);
      text-align: center;
    }
  }
  .star,
  .cursor {
    position: absolute;
    height: 100px;
  }
  .star {
    top: -50px;
    right: calc(50% + 50px);
    transform: rotate(0deg);
  }
  .cursor {
    bottom: -50px;
    left: calc(50% + 50px);
    animation: translate 4s ease-in-out infinite;
  }
  .link {
    color: ${theme.palette.black.main};
    text-decoration: none;
  }

  @keyframes translate{
    0 { transform: translate(0px, 0px)}
    10% { transform: translate(0px, 0px)}
    20% { transform: translate(-50px, -30px)}
    30% { transform: translate(-10px, -6px)}
    40% { transform: translate(-10px, -6px)}
    45% { transform: translate(-50px, -30px)}
    50% { transform: translate(-10px, -6px)}
    55% { transform: translate(-50px, -30px)}
    60% { transform: translate(0px, 0px)}
    100% { transform: translate(0px, 0px)}
  }
`);