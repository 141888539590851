import { useRef, useEffect, useState } from 'react';
import { Typography, Box, Stack } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Star } from '../../res/star.svg';

import Anchor from '../../components/Anchor';
import StyledButton from '../../components/StyledButton';
import ZoomStudent from '../../components/ZoomStudent';
import { Presentation11 } from './style';

function B11similarprofile(props) {
  const { gsap, lang, region, regionUrl, baseUrl } = props;
  const ref = useRef(null);

  const [openStudent1, setOpenStudent1] = useState(false);
  const [openStudent2, setOpenStudent2] = useState(false);

  const base = data[lang].b11;
  const imgBase = `${baseUrl}/images/students/`;
  const similarStudents = data[lang].similarStudents[region];
  const studentBase = data[lang].students;

  const handleCloseStudent1 = () => {
    setOpenStudent1(false);
  };

  const handleCloseStudent2 = () => {
    setOpenStudent2(false);
  };

  useEffect(() => {
    const elt = ref.current;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top center',
        end: '+=1000',
        preventOverlaps: true
      }
    });

    tl.from(elt.querySelector('.anim-title1'), { x: '-50vw', opacity: 0, duration: 0.3 })
      .from(elt.querySelector('.anim-title2'), { x: '50vw', opacity: 0, duration: 0.3, delay: 0.2 })
      .from(elt.querySelector('.anim-title3'), { x: '-50vw', opacity: 0, duration: 0.3, delay: 0.2 });

  }, [gsap]);

  const student1 = studentBase.find(element => element.name === similarStudents.student1);
  const student2 = studentBase.find(element => element.name === similarStudents.student2);

  return (
    <Presentation11 ref={ref}>
      <Anchor id='11' />
      <Box sx={{ position: 'relative', margin: 'auto', maxWidth: '600px', width: 'inherit', height: 'inherit' }}>
        <Box className='title'>
          <Typography variant='h3' className='title1 anim-title1'>{parse(base.title1)}</Typography>
          <Typography variant='h3' className='title2 anim-title2'>{parse(base.title2)}</Typography>
          <Typography variant='h3' className='title3 anim-title3'>{parse(base.title3)}</Typography>
        </Box>
        <img alt='' className={`student student1 ${student1.name}`} src={`${imgBase}${student1.similar_bg}`} />
        <img alt='' className='student student2' src={`${imgBase}${student2.similar_bg}`} />
        <Box className='wrapper'>
          <Star className='star' />
        </Box>
        <Box className='buttons'>
          <Stack direction='row' gap='25px' alignItems="center" justifyContent="center">
            <StyledButton onClick={() => setOpenStudent1(true)}>
              <Typography variant='body2' className='name'>{parse(student1.name)}</Typography>
              <Typography variant='body2' className='country'>{parse(student1.country)}</Typography>
            </StyledButton>
            <StyledButton onClick={() => setOpenStudent2(true)}>
              <Typography variant='body2' className='name'>{parse(student2.name)}</Typography>
              <Typography variant='body2' className='country'>{parse(student2.country)}</Typography>
            </StyledButton>
          </Stack>
          <Typography variant="body1" className='subtitle' textAlign="center">{parse(base.subtitle)}</Typography>
        </Box>
        <ZoomStudent
          student={student1}
          open={openStudent1}
          handleClose={handleCloseStudent1}
          baseUrl={baseUrl}
          lang={lang}
          regionUrl={regionUrl}
          region={region}>
        </ZoomStudent>
        <ZoomStudent
          student={student2}
          open={openStudent2}
          handleClose={handleCloseStudent2}
          baseUrl={baseUrl}
          lang={lang}
          regionUrl={regionUrl}
          region={region}>
        </ZoomStudent>
      </Box>
    </Presentation11>
  );
}

export default B11similarprofile;