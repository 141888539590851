import { useRef, useEffect } from 'react';

import { Typography, Box } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Hat } from '../../res/hat.svg';
import { ReactComponent as Plane } from '../../res/plane.svg';
import { ReactComponent as Logo } from '../../res/campus-logo.svg';
import { ReactComponent as Star } from '../../res/star.svg';
import { ReactComponent as Target } from '../../res/target.svg';
import { ReactComponent as Barcode } from '../../res/barcode.svg';
import { ReactComponent as GoDown } from '../../res/godown.svg';

import Anchor from '../../components/Anchor';
import { Intro } from './style';

function B00(props) {
  const { gsap, lang, baseUrl } = props;
  const ref = useRef(null);

  const base = data[lang].b00;
  const imgBase = `${baseUrl}/images/blocks/`;

  useEffect(() => {
    const elt = ref.current;

    gsap.timeline()
      .to(elt.querySelector('.pinktitle'), { delay: 0.8, opacity: 1, x: 0, y: 0 })
      .to(elt.querySelector('.bluetitle'), { opacity: 1, x: 0, y: 0 })
      .to(elt.querySelector('.godown'), { opacity: 1 })
      .to(elt.querySelector('.student1'), { opacity: 1 }, 2)
      .to(elt.querySelector('.student2'), { opacity: 1 }, 2)
      .to(elt.querySelector('.star'), { opacity: 1 }, 2)
      .to(elt.querySelector('.target'), { opacity: 1 }, 2)
      .to(elt.querySelector('.hat'), { opacity: 1 }, 3)
      .to(elt.querySelector('.plane'), { opacity: 1 }, 3)
      .to(elt.querySelector('.star'), { rotate: 360, duration: 10, repeat: -1, ease: 'linear' }, 0);

    gsap.to(elt.querySelector('.hat'), {
      scrollTrigger: {
        trigger: elt,
        start: 'top top',
        end: '+=250',
        // toggleActions: 'play none reverse none'
        scrub: 1,
      },
      duration: 0.5,
      rotation: -30
    });
  
    gsap.to(elt.querySelector('.plane'), {
      scrollTrigger: {
        trigger: elt,
        start: 'top top',
        end: '+=250',
        // toggleActions: 'play none reverse none'
        scrub: 1,
      },
      x: '-200%', 
      y: -200,
    });
    
    gsap.to(elt.querySelector('.godown'), {
      scrollTrigger: {
        trigger: elt.querySelector('.student1'),
        start: 'top center',
        end: 'top center',
        toggleActions: 'play none reverse none'
      },
      duration: 0.5,
      opacity: 0, 
    });
  }, [ gsap ]);

  return (
    <Intro ref={ref} className='intro is-loading'>
      <Anchor id='0'/>
      <Box className='container'>
        <Box className='pinWrapper'>
          <Logo className='logo' />
          <Typography variant='h2' className='title'>{parse(base.title1)}</Typography>
          <GoDown className='godown anim-godown' />
          <Typography variant='h2' className='pinktitle anim-title-pink' >{parse(base.title2)}</Typography>
          <Typography variant='h2' className='bluetitle anim-title-blue' >
            {parse(base.title3)}
            <Barcode className='barcode'></Barcode>
          </Typography>
          <Star className='star' />
          <img alt='' className='student2' src={`${imgBase}${base.image2}`} />
          <Target className='target' />          
          <img alt='' className='student1' src={`${imgBase}${base.image1}`} />
          <Hat className='hat' />
          <Plane className='plane' />
        </Box>
      </Box>
    </Intro>
  );
}

export default B00;