import { Box } from '@mui/material';
import styled from 'styled-components';

export const Presentation11 = styled(Box)(
  ({ theme }) => `
  position: relative;
  height: 100vh;
  width: 100%;
  background-color: ${theme.palette.yellow.main};
  .wrapper {
    position: absolute;
    z-index: 0;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    width: 80vw;
    max-width: 600px;
  }
  .star {
    animation: rotation 40s infinite linear;
  }
  .title {
    position: relative;
    z-index: 1;
    padding-left: ${theme.spacing(2)};
    padding-top: ${theme.spacing(2)};
    color: ${theme.palette.white.main};
    white-space: nowrap;
    transform: rotate(-3deg);
    text-align: center;
    & > * {
      font-weight: 900;
    }
    .title1 {
      transform: translate(-70px, 0px);
    }
    .title2 {
      transform: translate(30px, 0px);
    }
    .title3 {
      transform: translate(-60px, 0px);
    }
  }
  .green {
    color: ${theme.palette.green.main};
  }
  .student {
    position: absolute;
    z-index: 1;
    top: 20%;
    height: min(60vh, 500px);
  }
  .student2 { 
    right: 50%;
    transform: translateX(calc(50% + 110px));
  }
  .student1 { 
    left: 50%;
    transform: translateX(calc(-50% - 110px));
    &.Luis {
    transform: translateX(calc(-50% - 60px));
    z-index: 5;

    }
  }
  .buttons {
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translateX(-50%);
  }
  .name {
    font-weight: 900;
    text-transform: uppercase;
    color: ${theme.palette.white.main};
  }
  .country {
    text-transform: capitalize;
    color: ${theme.palette.yellow.main};
  }
  .subtitle {
    width: 80vw;
    padding-top: ${theme.spacing(2)};
    color: ${theme.palette.green.main};
  }
`);