import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason2 = styled(Box)(
  ({ theme }) => `
  position: relative;
  height: 100vh;
  background-color: ${theme.palette.yellow.main};
  .wrapper {
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    position: absolute;
    top: 20vh;
    width: 100%;
  }
  .title-wrapper {
    position: relative;
    margin: auto;
    width: fit-content;
    text-align: center;
  }
  .title {
    font-weight: 500;
    color: ${theme.palette.teal.main};
  }
  .hat {
    position: absolute;
    right: -30px;
    top: -50px;
    transform: rotate(30deg);
    height: 75px;
  }
  .frame {
    position: relative;
    background-color: ${theme.palette.orange.light};
    color: ${theme.palette.white.main};
    margin-top: ${theme.spacing(1)};
    padding: ${theme.spacing(1)} ${theme.spacing(2)};
    text-transform: lowercase;
    transform: rotate(-3deg);

    .corner {
      position: absolute;
      border-radius: 50%;
      background-color: ${theme.palette.yellow.main};
      height: 40%;
      aspect-ratio: 1;
      top: 50%;
    }
    .corner1 {
      right: 0;
      transform: translate(50%, -50%);
    }
    .corner2 {
      left: 0;
      transform: translate(-50%, -50%);
    }
  }
  .wrapper2 {
    position: absolute;
    top: 50vh;
    width: 100%;
    .disk {
      position: relative;
      margin: auto;
      width: 50vw;
      max-width: 310px;
      aspect-ratio: 1;
      border-radius: 50%;
      overflow: hidden;
    }
    .disk::after{
      content: '';
      position: absolute;
      border-radius: 50%;
      display: block;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      box-shadow: inset 18px -10px 0px -2px rgba(0, 0, 0, 0.1);
    }
    .map {
      position: absolute;
      z-index: -1;
      right: 0;
      top: 0;
      height: 100%;
    }
    .pin {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 15%; 
      transform: translateX(-50%);
      height: 20px;
      ${theme.breakpoints.up(750)} {
        top: 60px;
      }
    }
  }
`);