
import { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import B00intro from './blocks/B00intro';
import B01training from './blocks/B01training';
import B02rendezvous from './blocks/B02rendezvous';
import B03english from './blocks/B03english';
import B04french from './blocks/B04french';
import B05teaching from './blocks/B05teaching';
import B06research from './blocks/B06research';
import B07cost from './blocks/B07cost';
import B08economicpower from './blocks/B08economicpower';
import B09label from './blocks/B09label';
import B10art from './blocks/B10art';
import B11similarprofile from './blocks/B11similarprofile';
import B12otherprofile from './blocks/B12otherprofile';
import B13cursus from './blocks/B13cursus';
import B14footer from './blocks/B14footer';
import Nav from './components/Nav';

import { AppContainer } from './style';

const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;
gsap.registerPlugin(ScrollTrigger);

function App() {

  const [activeBlockId, setActiveBlockId] = useState('');

  function getLang() {
    let { search } = document.location;
    let language = window.navigator.languages[0];
    let lang = 'en';

    if (language) {
      if (language.toLocaleLowerCase().includes('fr'))
        lang = 'fr';
    }

    const params = search.split('?')[1];
    
    if (params) {
      const attributs = params.split('&');
      attributs.forEach(attribut => {
        const name = attribut.split('=')?.[0];
        const value = attribut.split('=')?.[1];
        if (name === 'lang' && value.toLocaleLowerCase().includes('fr')) {
          lang = 'fr';
        } else if (name === 'lang' && value.toLocaleLowerCase().includes('en')) {
          lang = 'en';
        }
      });
    }

    return lang;
  }
  
  function getRegion() {
    let { search } = document.location;
    let preferredRegion = 'default';
    const regions = ['india', 'indonesia', 'japan', 'korea', 'vietnam', 'germany', 'england', 'nigeria', 'rwanda', 'ghana', 'colombia', 'mexico', 'brazil', 'saudiarabia'];
    
    const params = search.split('?')[1];
    if(params){
      const attributs = params.split('&');
      attributs.forEach(attribut => {
        const name = attribut.split('=')?.[0];
        const value = attribut.split('=')?.[1];
        if (name === 'region' && regions.includes(value))
          preferredRegion = value;
      });
    }
    return preferredRegion;
  }  
  
  function onNavClick(id) {
    if (id === 0) {
      window.scroll({ top: 0, behavior: 'smooth' });
    } else {
      const element = document.getElementById(id);
      const isPinned = !!element.closest('.pin-spacer');
      const offsetToReach = isPinned ? element.closest('.pin-spacer').parentNode.offsetTop : element.parentNode.offsetTop;
      window.scroll({ top: offsetToReach, behavior: 'smooth' });
    }
  }

  const lang = getLang();
  const region = getRegion();
  const regionsUrls = {
    'default': 'https://www.campusfrance.org/en', 
    'india': 'https://www.inde.campusfrance.org/', 
    'indonesia': 'https://www.indonesie.campusfrance.org/', 
    'japan': 'https://www.japon.campusfrance.org/fr', 
    'korea': 'https://www.coree.campusfrance.org/fr', 
    'vietnam': 'https://www.vietnam.campusfrance.org/vi', 
    'germany': 'https://www.allemagne.campusfrance.org/', 
    'england': 'https://www.uk.campusfrance.org/', 
    'nigeria': 'https://www.nigeria.campusfrance.org/', 
    'rwanda': 'https://www.rwanda.campusfrance.org/', 
    'ghana': 'https://www.ghana.campusfrance.org/', 
    'colombia': 'https://www.colombie.campusfrance.org/', 
    'mexico': 'https://www.mexique.campusfrance.org/fr'
  };

  const regionUrl = regionsUrls[region];

  const handleActive = (position) => {
    setActiveBlockId(position);
  };
  
  return (
    <AppContainer>
      <InView threshold={0.3} onChange={inView => inView ? handleActive(0) : ''} >
        <B00intro baseUrl={BASE_URL} lang={lang} region={region} gsap={gsap} />
      </InView>
      <InView threshold={0.2}  onChange={inView => inView ? handleActive(1) : ''} >
        <B01training baseUrl={BASE_URL} lang={lang} region={region} gsap={gsap} />
      </InView>
      <InView threshold={0.1} onChange={inView => inView ? handleActive(2) : ''} >
        <B02rendezvous baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.1} onChange={inView => inView ? handleActive(3) : ''} >
        <B03english baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.1} onChange={inView => inView ? handleActive(4) : ''} >
        <B04french baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.1} onChange={inView => inView ? handleActive(5) : ''} >
        <B05teaching baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.1} onChange={inView => inView ? handleActive(6) : ''} >
        <B06research baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.3} onChange={inView => inView ? handleActive(7) : ''} >
        <B07cost baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.5} onChange={inView => inView ? handleActive(8) : ''} >
        <B08economicpower baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.2} onChange={inView => inView ? handleActive(9) : ''} >
        <B09label baseUrl={BASE_URL} lang={lang} region={region} gsap={gsap}/>
      </InView>
      <InView threshold={0.3} onChange={inView => inView ? handleActive(10) : ''} >
        <B10art baseUrl={BASE_URL} lang={lang} region={region}  gsap={gsap} />
      </InView>
      <InView threshold={0.2} onChange={inView => inView ? handleActive(11) : ''} >
        <B11similarprofile baseUrl={BASE_URL} lang={lang} region={region} regionUrl={regionUrl} gsap={gsap}/>
      </InView>
      <InView threshold={0.2} onChange={inView => inView ? handleActive(12) : ''} >
        <B12otherprofile baseUrl={BASE_URL} lang={lang} region={region} regionUrl={regionUrl} gsap={gsap}/>
      </InView>
      <InView threshold={0.5} onChange={inView => inView ? handleActive(13) : ''} >
        <B13cursus baseUrl={BASE_URL} lang={lang} region={region} regionUrl={regionUrl} gsap={gsap}/>
      </InView>
      <InView onChange={inView => inView ? handleActive(14) : ''} >
        <B14footer baseUrl={BASE_URL}  lang={lang} region={region} gsap={gsap}/>
      </InView>
      <Nav lang={lang} region={region} activeBlockId={activeBlockId} onNavClick={onNavClick} />
    </AppContainer>
  );
}

export default App;
