import { Box, DialogTitle, DialogContent, Typography } from '@mui/material';
import React from 'react';

import { ReactComponent as Snake } from '../../res/snake.svg';
import { ReactComponent as Macaron } from '../../res/macaron.svg';
import { ReactComponent as Calc } from '../../res/calc.svg';
import { ReactComponent as MacaronPink } from '../../res/macaron2.svg';
import { ReactComponent as CalcYellow } from '../../res/calc2.svg';

import parse from '../../utils/parse';

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { StyledDialog } from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ZoomStudent(props) {
  const { open, student } = props; 

  const imgBase = `${props.baseUrl}/images/students/`;

  const handleClose = () => {
    props.handleClose();
  };

  const bgColor = student.bgcolor ? 'bg-' + student.bgcolor : '';
  const btncolor = student.btncolor ? student.btncolor : '';

  return (
    <StyledDialog
      sx={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
      open={open}
      TransitionComponent={Transition}
      onClose={handleClose}
      aria-describedby="alert-dialog"
      className={`${bgColor} ${btncolor}`}
    >
      <CloseIcon className='close'/>
      <DialogTitle className='title'>{parse(student.name)}</DialogTitle>
      <DialogContent className='content'>
        <Typography variant='body2' className='country'>{`${parse(student.country)}, ${parse(student.specialty)}`}</Typography>
        <Typography variant='body2' className='desc'>{parse(student.desc)}</Typography>
        <Typography variant='body2' className='building'>{parse(student.building)}</Typography>
        <Typography variant='body2' className='desc'>{parse(student.descBuilding)}</Typography>
      </DialogContent>
      <Box className='border'></Box>
      <Box className='pics'>
        {student.macaron === 'pink' ? (
          <MacaronPink className='macaron'></MacaronPink>
        ) : (
          <Macaron className='macaron'></Macaron>
        )}
        <img alt='' className='pic ' src={`${imgBase}${student.pic}`} />
        {student.calc === 'yellow' ? (
          <CalcYellow className='calc'></CalcYellow>
        ) : (
            <Calc className='calc'></Calc>
        )}
      </Box>
      <Snake className='snake'></Snake>
    </StyledDialog>
  );
}

export default ZoomStudent;
