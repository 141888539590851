import { Typography, Stack } from '@mui/material';
import { data } from '../../utils/data';
import { Menu } from './style';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import SegmentIcon from '@mui/icons-material/Segment';
import ShareIcon from '@mui/icons-material/Share';

function Nav(props) {
  const { activeBlockId } = props;
  const nav = data[props.lang].nav;
  const navIcons = {
    0: HomeIcon,
    11: GroupIcon,
    12: ViewCarouselIcon,
    13: SegmentIcon,
    14: ShareIcon,
  };

  const numbersNav = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

  return (
    <Menu>
      <Stack
        direction="row"
      >
        {
          nav.map((item, idx) => {

            const Logo = numbersNav.includes(item.id) ? null : navIcons[item.id];
            return (
              <Stack
                key={idx}
                justifyContent="center"
                alignItems="center"
                className={`item ${item.id === activeBlockId ? 'active' : ''}`}
                onClick={() => props.onNavClick(item.id)}
              >
                <Typography sx={{display: 'flex'}} variant="body1">{Logo ? < Logo style={{width: '18px'}} /> : item.id}</Typography>
              </Stack>)
          })
        }
      </Stack>
    </Menu>
  )
}

export default Nav;
