import { Stack } from '@mui/material';
import styled from 'styled-components';

export const Reason6 = styled(Stack)(
  ({ theme }) => `
  position: relative;
  height: 100vh;
  background-color: ${theme.palette.pink.main};
  padding-bottom: ${theme.spacing(10)};
  .content {
    height: 100vh;
    position: relative;
    max-width: 600px;
    margin: auto;
  }
  .sticker {
    position: relative;
    z-index: 1;
    width: min(100vw, 480px);
    max-width: 360px;
  }
  .arrow, 
  .star,
  .light,
  .hexa,
  .img,
  .atom,
  .pi {
    position: absolute;
  }
  .arrow {
    height: 150px;
    right: 50%;
    top: calc(50% - 150px);
    transform: translateY(-50%);
  }
  .star {
    height: 150px;
    left: 50%;
    bottom: calc(50% - 150px);
    transform: translateY(50%);
  }
  .light {
    height: 200px;
    left: 50%;
    bottom: calc(50% + 75px);
  }
  .atom {
    height: 150px;
    svg {
      height: 100%;
    }
  }
  .hexa {
    height: 150px;
    bottom: 75%;
    svg {
      height: 100%;
    }
  }
  .pi {
    top: calc(50% + 200px);
    right: 5%;        
  }
  .img {
    height: 150px;
    top: calc(50% + 200px);
    right: 20%;    
  }
  .title {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(-5deg);
    text-align: center;
  }
  .title1 {
    width: 60vw;
    margin: auto;
    font-weight: 900;
    font-size: 1.5rem;
    color: ${theme.palette.blue.light};
  }
  .title2 {
    width: 80vw;
    font-size: 1.5rem;
    font-family: 'vFraunces', serif;
    color: ${theme.palette.white.main};
  }
  .ftext {
    position: absolute;
  }
  .ftitre {
    font-size: 4.5rem;
    font-weight: 700;
    font-family: 'vFraunces', serif;
  }
  .f1text, 
  .f2text,
  .f3text {
    line-height: 1.2;
  }
  .f1text {
    z-index: 3;
    right: 50%;
    bottom: calc(70%);
    transform: translateY(-50%);
    text-align: right;
    color: ${theme.palette.white.main};
    &2 {
      font-size: 0.6rem;
    }
  }
  .ftitre1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${theme.palette.white.main};
  }   
  .f1text2 {
    font-size: 0.8rem;
    .tiny {
      font-size: 0.6rem;
    }
  }   
  .f2text {
    z-index: 3;
    top: calc(50% + 75px);
    left: 45%;
    width: 200px;
    padding-bottom: ${theme.spacing(1)};
    transform: rotate(5deg);
    border-radius: 20px;
    text-align: center;
    background-color: ${theme.palette.teal.main};
    color: ${theme.palette.white.main};
    .ftitre {
      font-family: 'Silka', sans-serif;
      line-height: 1;
    }
  }
  .f3text {
    z-index: 3;
    left: 5%;
    top: 60%;
    text-align: left;
    color: ${theme.palette.white.main};
    width: 150px;
    
    .ftitre {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .f1text3 {
    position: absolute;
    z-index: 4;    
    left: 5%;
    top: calc(50% + 210px);
    text-align: left;
  }  
`);