import { Stack } from '@mui/material';
import styled from 'styled-components';

export const Footer14 = styled(Stack)(
  ({ theme }) => `
  position: relative;
  z-index: 0;
  height: 400px;
  background-color: ${theme.palette.blue.main};
  .logo {
    height: 80px;
    margin-top: ${theme.spacing(4)};
  }
  .socials {
    margin-top: ${theme.spacing(16)};
    gap: ${theme.spacing(1)};
    .social {
      width: 30px;
      img {
        width: 30px;
      }
    }
  }
`);