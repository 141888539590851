export const data = {
  fr: {
    b00: {
      title1: '10 BONNES<br/>RAISONS',
      title2: 'D\'ÉTUDIER',
      title3: 'EN FRANCE',
      image1: 'b00-student1.png',
      image2: 'b00-student2.png',
    },
    b01: {
      title1: 'Des formations<br/>Dans tous',
      title2: '<b>les domaines</b>',
      title3: '<b>et tout le pays</b>',
      image1: 'b01-polaroid1.png',
      image2: 'b01-polaroid2.png',
      image3: 'b01-polaroid3.png',
      image4: 'b01-polaroid4.png',
      image5: 'b01-polaroid5.png',
      image6: 'b01-polaroid6.png',
    },
    b02: {
      title1: 'LE RENDEZ-VOUS',
      title2: 'DES ÉTUDIANTS',
      title3: 'DU MONDE ENTIER',
    },
    b03: {
      title1: 'ÉTUDIEZ EN',
      title2: 'ANGLAIS',
      title3: 'au cœur',
      title4: 'de l\'Europe',
      travel1: '<b><i>→ DEPARTURES</i></b>',
      travel2: '15:20 LONDON <b><i>ON TIME</i></b>',
      travel3: '<b><i>→ ARRIVALS</i></b>',
      travel4: '17:30 PARIS',
    },
    b04french: {
      final: [
        '             ',
        'LE FRANÇAIS  ',
        '             ',
        '             ',
        '     UN ATOUT',
        '             ',
        '             ',
        'POUR VOTRE CV',
        '             ',
      ],
      title1: '5<sup>e</sup>',
      desc1: 'langue la plus parlée dans le monde',
      title2: '3<sup>e</sup>',
      desc2: 'langue des affaires',
      title3: '4<sup>e</sup>',
      desc3: 'langue d\'internet',
    },
    b05: {
      title1: 'Un enseignement',
      title2: 'DE RANG MONDIAL',
      feature1order: '3<sup>e</sup>',
      feature1text: 'place du classement mondial de Shanghai',
      feature2order: 'En mathématiques,<br> la meilleure université du monde est française',
      feature2text: '',
      feature3order: 'TOP 5',
      feature3text: 'des meilleurs masters en finance du <i>Financial Times</i> 100% français',
    },
    b06: {
      title1: 'UNE GRANDE NATION',
      title2: 'DE RECHERCHE ET D\'INNOVATION',
      feature1: '2<sup>e</sup>',
      feature1b: '<b>pays européen<br>le plus innovant</b><br><span class="tiny">(Office européen des brevets)</span>',
      feature2: '2<sup>e</sup>',
      feature2b: 'rang mondial pour les médailles Fields (mathématiques)',
      feature3: '4<sup>e</sup>',
      feature3b: 'rang mondial pour les Prix Nobel',
      image: 'b06-labo.png',
    },
    b07: {
      title1: 'Des études',
      title2: 'Coûteuses',
      title3: 'À COÛT MODÉRÉ',
      euro: '€',
      desc1: 'Dans nos universités publiques, un étudiant non-européen paye moins de',
      price: '4 000 €',
      desc2: 'pour une année de master, et un Européen étudie quasi-gratuitement.',
      image1: 'b07-pig.png',
      image2: 'b07-pig2.png',
    },
    b08: {
      title1: 'Se former au sein d\'une grande',
      title2: 'puissance',
      title3: 'économique',
    },
    b09: {
      title1: 'UN LABEL',
      title2: 'POUR GARANTIR',
      title3: 'VOTRE ACCUEIL',
    },
    b10: {
      title: 'UN ART DE VIVRE UNIQUE AU MONDE',
      image1: 'b10-illus1.png',
      image2: 'b10-illus2.png',
      image3: 'b10-illus3.png',
      image4: 'b10-illus4.png',
      image5: 'b10-illus5.png',
      image6: 'b10-illus6.png',
    },
    b11: {
      title1: 'SKILLS',
      title2: '& FRIENDS',
      title3: '<span class="green">FOR LIFE</span>',
      subtitle: 'Découvrez l\'histoire d\'étudiants qui vous ressemblent dans leur parcours d\'études en France',
    },
    b12: {
      title1: 'DÉCOUVREZ<br/>D\'AUTRES PARCOURS',
      title2: 'inspirants',
    },
    b13: {
      title: 'DÉCOUVREZ QUEL CURSUS SUIVRE EN FRANCE',
      cursus: [
        'Économie',
        'Arts',
        'Lettres',
        'Physique',
        'Ingénierie',
        'Droit',
        'Gestion',
        'Communication',
        'Marketing',
        'Agroalimentaire',
        'Architecture',
        'Urbanisme',
        'Design',
        'Mode',
        'Biologie',
        'Chimie',
        'Journalisme',
        'Informatique',
        'Mathématiques',
        'Médecine',
        'Philosophie',
        'Éducation',
        'Sports',
        'Hôtellerie',
        'Logistique',
      ],
      cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
    },
    nav: [
      { id: 0, name: 'intro' },
      { id: 1, name: 'raison1' },
      { id: 2, name: 'raison2' },
      { id: 3, name: 'raison3' },
      { id: 4, name: 'raison4' },
      { id: 5, name: 'raison5' },
      { id: 6, name: 'raison6' },
      { id: 7, name: 'raison7' },
      { id: 8, name: 'raison8' },
      { id: 9, name: 'raison9' },
      { id: 10, name: 'raison10' },
      { id: 11, name: 'profil similaire' },
      { id: 12, name: 'autre profil' },
      { id: 13, name: 'cursus' },
      { id: 14, name: 'footer' },
    ],
    similarStudents: {
      default: {
        student1: 'Sothearath',
        student2: 'Claudia',
      },
      india: {
        student1: 'Sarah',
        student2: 'Pankaj',
      },
      indonesia: {
        student1: 'Junghwa',
        student2: 'Hariyo',
      },
      japan: {
        student1: 'Sothearath',
        student2: 'Sarah',
      },
      korea: {
        student1: 'Junghwa',
        student2: 'Sarah',
      },
      vietnam: {
        student1: 'Dang Duy Anh',
        student2: 'Sothearath',
      },
      germany: {
        student1: 'Niels',
        student2: 'Alicia',
      },
      england: {
        student1: 'Alyssa',
        student2: 'Angelo',
      },
      nigeria: {
        student1: 'Claudia',
        student2: 'Sandra',
      },
      rwanda: {
        student1: 'Lucas',
        student2: 'Tahina',
      },
      ghana: {
        student1: 'Claudia',
        student2: 'Tchailga',
      },
      colombia: {
        student1: 'Valentina',
        student2: 'Luis',
      },
      mexico: {
        student1: 'Veronica',
        student2: 'Luis',
      },
      brazil: {
        student1: 'Luis',
        student2: 'Isabelle',
      },
      saudiarabia: {
        student1: 'Turki Ayed',
        student2: 'Moza',
      },
    },
    students: [
      {
        name: 'Pankaj',
        country: 'Inde',
        specialty: 'Consultant Indépendant',
        desc: "Aujourd’hui, Pankaj est fondateur de de plusieurs start-ups à la pointe de l’innovation et intervenant indépendant au sein de grandes écoles françaises de management. En somme, un homme d’affaires accompli !",
        building: 'Master en international Business',
        descBuilding: 'Montpellier Business School',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'pankaj-popup.png',
        bigpic: 'pankaj-slide.jpg',
        similar_bg: 'pankaj-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Sarah',
        country: 'Corée du sud',
        specialty: 'Experte en marketing de luxe',
        desc: "Si Paris est la capitale de la mode et de la création, elle est avant tout celle du luxe. C’est la raison principale pour laquelle Sarah a quitté son Séoul natal pour s’installer dans la Ville Lumière. Il n’y avait pas de ville plus idéale pour se former au marketing du luxe.",
        building: 'Management de luxe',
        descBuilding: 'Sciences Po Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'yellow',
        pic: 'sarah-popup.png',
        bigpic: 'sarah-slide.jpg',
        similar_bg: 'sarah-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Hariyo',
        country: 'Indonésie',
        specialty: 'Viticulteur',
        desc: "En visite en Bourgogne pour apprendre la culture du vin français, cet ancien étudiant en gastronomie s’est pris de passion pour les vignobles français et a décidé d’entamer un Brevet Professionnel Responsable d’Entreprise Agricole à Beaune. Il est aujourd’hui le premier vigneron indonésien installé en Bourgogne et gérant du Domaine de la Main d’Or.",
        building: 'Formation professionnelle en viticulture et œnologie',
        descBuilding: '',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'hariyo-popup.png',
        bigpic: 'hariyo-slide.jpg',
        similar_bg: 'hariyo-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Junghwa',
        country: 'Corée du sud',
        specialty: 'Ingénieure en aéronautique',
        desc: "Après une première année de Master Recherche en ingénierie mécanique obtenue en Corée, Junghwa choisit de poursuivre ses études en France où elle mène brillamment un nouveau master en recherche aéronautique. Passionnée par l’aéronautique et chercheuse dans l’âme, elle est aujourd’hui membre du laboratoire de l’EM2C.",
        building: 'Doctorat au laboratoire Énergétique Moléculaire et Macroscopique, Combustion (EM2C)',
        descBuilding: 'Université Paris Saclay',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'junghwa-popup.png',
        bigpic: 'junghwa-slide.jpg',
        similar_bg: 'junghwa-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Sothearath',
        country: 'Cambodge',
        specialty: 'Project manager',
        desc: "Jeune docteur en économie dont les travaux portaient sur les monnaies locales, il est actuellement consultant dans l’énergie au Cambodge.",
        building: 'Doctorat en économie',
        descBuilding: 'Université de Côte d’Azur',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'sothearath-popup.png',
        bigpic: 'b12-sothearath.jpg',
        similar_bg: 'sothearath-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Dang Duy Anh',
        country: 'Vietnam',
        specialty: 'Économiste du développement',
        desc: "Lauréat du programme Make our planet great again et sensible aux questions environnementales, il poursuit ses recherches en économie du développement.",
        building: 'Master en économie',
        descBuilding: 'Paris School of Economics',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'dang-popup.png',
        bigpic: 'dang-slide.jpg',
        similar_bg: 'dang-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Niels',
        country: 'Allemagne',
        specialty: 'Chercheur en changement climatique',
        desc: "Lauréat du programme MOPGA en 2018, Niels réalise un doctorat en Géologie à l’Université Claude Bernard Lyon 1 et participe activement à la recherche sur le réchauffement climatique.",
        building: '',
        descBuilding: 'Université Claude Bernard Lyon 1',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'niels-popup.png',
        bigpic: 'niels-slide.jpg',
        similar_bg: 'niels-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Alicia',
        country: 'Espagne',
        specialty: 'Interprète de conférence et traductrice',
        desc: "Diplômée d’un master 2 d’interprétation de conférence à Strasbourg, elle travaille comme traductrice dans le domaine des transports et de l’ingénierie.",
        building: '',
        descBuilding: 'Université de Strasbourg',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'alicia-popup.png',
        bigpic: 'b12-alicia.jpg',
        similar_bg: 'alicia-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Alyssa',
        country: 'Australie',
        specialty: 'Chargée de communication à l’international',
        desc: "Suite à un échange académique, Alyssa choisit de s’installer à Paris après ses études. Elle est aujourd’hui chargée de communication sur le monde anglophone, au sein du service de développement international à Sorbonne Université.",
        building: '',
        descBuilding: 'Sciences Po Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'alyssa-popup.png',
        bigpic: 'b12-alyssa.jpg',
        similar_bg: 'alyssa-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Angelo',
        country: 'Italie',
        specialty: 'Designer et photographe',
        desc: "Après un échange académique à Paris dans le cadre de son Master of European Design, il travaille aujourd’hui comme designer et photographe.",
        building: '',
        descBuilding: 'Ecole Nationale Supérieure de Création Industrielle <br> Les Ateliers (ENSCI) Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'angelo-popup-v2.png',
        bigpic: 'b12-angelo.jpg',
        similar_bg: 'angelo-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Sandra',
        country: 'Nigéria',
        specialty: 'Entrepreneuse engagée pour l’éducation',
        desc: "Après avoir obtenu une licence en Relations Internationales à Londres, Sandra choisit de poursuivre ses études en France, à l’iaelyon School of Management dans le cadre d’un Master en management des Affaires internationales.",
        building: 'Master en International Business',
        descBuilding: 'IAE de Lyon',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'sandra-popup.png',
        bigpic: 'sandra-slide.jpg',
        similar_bg: 'sandra-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Claudia',
        country: 'Gabon',
        specialty: 'Etudiante en business administration, Boursière de l’Agence Nationale des Bourses du Gabon',
        desc: "Passionnée par la finance, elle étudie l’audit dans une école de commerce à Lyon.",
        building: 'Emlyon Business School',
        descBuilding: 'Saint-Etienne',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'claudia-popup.png',
        bigpic: 'b12-claudia.jpg',
        similar_bg: 'claudia-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Lucas',
        country: 'Kenya',
        specialty: 'Docteur en histoire de l’art et archéologie',
        desc: "Passionné par les liens culturels entre le Kenya et la France, il vient d’être diplômé d’un doctorat en histoire de l’art et archéologie.",
        building: 'Master 1 et Master 2 en Histoire, Civilisation et Patrimoine puis Doctorat en Histoire de l’Art et Archéologie',
        descBuilding: 'Université de Pau et des Pays de l’Adour',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'lucas-popup.png',
        bigpic: 'b12-lucas.jpg',
        similar_bg: 'lucas-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Tahina',
        country: 'Madagascar',
        specialty: 'Ingénieure-chercheuse sur les drones',
        desc: "Aujourd’hui, Tahina est ingénieure-chercheuse au CEA sur le campus de Paris-Sacla, travaillant activement sur un projet européen concernant les drones.",
        building: 'Université de la Réunion (ESIROI)',
        descBuilding: 'Campus de Paris-Saclay',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'tahina-popup.png',
        bigpic: 'tahina-slide.jpg',
        similar_bg: 'tahina-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Tchailga',
        country: 'Côte d’Ivoire',
        specialty: 'Chargée de projet en Mauritanie',
        desc: "Après un master en économie du développement et de l’action humanitaire, elle travaille dans la gestion de projets humanitaires.",
        building: ' Master 1 d’Economie et management public puis Master 2 Ingénierie du Développement et de l’Action Humanitaire',
        descBuilding: 'Université de Lille',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'tchailga-popup.png',
        bigpic: 'b12-tchailga.jpg',
        similar_bg: 'tchailga-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Luis',
        country: 'Colombie',
        specialty: 'Data Manager',
        desc: "Après des études d’ingénieur à Metz, Luis est expert en qualité dans le secteur pharmaceutique.",
        building: 'Master de génie industriel à l’Ecole nationale d’Ingénieurs',
        descBuilding: 'Metz',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'luis-popup.png',
        bigpic: 'b12-luis.jpg',
        similar_bg: 'luis-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Valentina',
        country: 'Argentine',
        specialty: 'Responsable de projets à l’international dans l’enseignement supérieur',
        desc: "Lauréate de la bourse Eiffel, elle est responsable de l’alliance européenne EU4DUAL de l’ESTIA, prestigieuse école d’ingénieur au Pays basque",
        building: 'Master 2 à Sciences Po Bordeaux en Affaires Internationales -Politique et développement en Afrique et dans les pays du Sud (aujourd’hui appelé « Risques et développement aux Suds »)',
        descBuilding: '',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'valentina-popup.png',
        bigpic: 'b12-valentina.jpg',
        similar_bg: 'valentina-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Veronica',
        country: 'Mexique',
        specialty: 'Cheffe de projets technologiques',
        desc: "Ancienne élève du Lycée franco-mexicain (AEFE) à Mexico, le choix de la France a été naturel pour Veronica. Après une classe préparatoire physique-technologie et sciences de l’ingénieur à Lyon, elle intègre Arts et Métiers Paris Tech (ENSAM).",
        building: '',
        descBuilding: 'Art et Métiers Paris Tech (ENSAM)',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'veronica-popup.png',
        bigpic: 'veronica-slide.jpg',
        similar_bg: 'veronica-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Isabelle',
        country: 'Brésil',
        specialty: 'Artiste designer',
        desc: "Cinq ans après son arrivée à Paris et sa marque de mode créée, l’artiste designer nous ouvre les portes de son atelier installé au cœur de la capitale de la mode où elle se remémore son parcours pour notre plus grand plaisir. Regardez !",
        building: 'MBA Luxury and Fashion Management<br>MBA International Management',
        descBuilding: 'Paris School of Business <br>ESGCI, Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'isabelle-popup.png',
        bigpic: 'isabelle-slide.jpg',
        similar_bg: 'isabelle-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Turki Ayed',
        country: 'Arabie Saoudite',
        specialty: 'Expert en distribution et qualité alimentaire',
        desc: "Venu du désert d’Arabie, Turki Ayed a choisi la ville de Lille, dans le nord de la France, pour préparer son diplôme en distribution et qualité des produits alimentaires. Les enjeux de l’agroalimentaire revêtent à ses yeux une importance majeure, compte-tenu des ambitions de développement de cette industrie dans son pays et dans sa région.",
        building: 'DEUST Distribution et Qualité des Produits Alimentaires',
        descBuilding: 'Université de Lille',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'turki-ayed-popup.png',
        bigpic: 'turki-ayed-slide.jpg',
        similar_bg: 'turki-ayed-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Moza',
        country: 'Qatar',
        specialty: 'Doctorante en philosophie',
        desc: "Diplômée d’un Master of Science à Doha, elle poursuit un doctorat dans le domaine de l’agro-alimentaire.",
        building: '',
        descBuilding: 'AgroParisTech Paris Saclay',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'moza-popup.png',
        bigpic: 'b12-moza.jpg',
        similar_bg: 'moza-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
    ],
    socials: [
      {
        name: 'Facebook',
        url: 'https://fr-fr.facebook.com/CampusFranceParis/',
        image: 'facebook.png',
      },
      {
        name: 'France Alumni',
        url: 'https://www.francealumni.fr/fr',
        image: 'francealumni.png',
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/campus_france/',
        image: 'instagram.png',
      },
      {
        name: 'LinkedIn',
        url: 'https://fr.linkedin.com/company/campusfrance',
        image: 'linkedin.png',
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/campusfrance?lang=fr',
        image: 'twitter.png',
      },
      {
        name: 'Youtube',
        url: 'https://www.youtube.com/user/ParisCampusFrance',
        image: 'youtube.png',
      },
    ]
  },
  en: {
    b00: {
      title1: '10 GOOD<br/>REASONS',
      title2: 'TO STUDY',
      title3: 'IN FRANCE',
      image1: 'b00-student1.png',
      image2: 'b00-student2.png',
    },
    b01: {
      title1: 'A wide choice<br/>',
      title2: '<b>of academic programs</b>',
      title3: '<b>all over the country</b>',
      image1: 'b01-polaroid1.png',
      image2: 'b01-polaroid2.png',
      image3: 'b01-polaroid3.png',
      image4: 'b01-polaroid4.png',
      image5: 'b01-polaroid5.png',
      image6: 'b01-polaroid6.png',
    },
    b02: {
      title1: 'An attractive destination',
      title2: 'FOR INTERNATIONAL',
      title3: 'STUDENTS',
    },
    b03: {
      title1: 'STUDY IN',
      title2: 'ENGLISH',
      title3: 'in the heart',
      title4: 'of Europe',
      travel1: '19:20 PARIS - LONDON <b><i>ON TIME</i></b>',
      travel2: '→ DEPARTURES',
      travel3: '20:30 LYON - MILAN <b><i>ON TIME</i></b>',
      travel4: '21:05 PARIS - MADRID <b><i>ON TIME</i></b>',
      travel5: '21:50 PARIS - ROME <b><i>ON TIME</i></b>',
      travel6: '22:40 MARSEILLE - BARCELONA',
      travel7: '→ ARRIVALS',
    },
    b04french: {
      final: [
        '  FRENCH     ',
        '  LANGUAGE   ',
        '             ',
        ' AN  ADDED   ',
        '     BONUS    ',
        '             ',
        ' FOR         ',
        ' YOUR RESUME ',
        '             ',
      ],
      title1: '5<sup>th</sup>',
      desc1: 'most widely spoken language in the world',
      title2: '3<sup>rd</sup>',
      desc2: 'language of business',
      title3: '4<sup>th</sup>',
      desc3: 'language of the internet',
    },
    b05: {
      title1: 'WORLD-CLASS',
      title2: 'education',
      feature1order: '3<sup>rd</sup>',
      feature1text: 'place in the Shanghai Ranking',
      feature2order: 'The best university in the world in mathematics is in France',
      feature2text: '',
      feature3order: 'THE TOP 5',
      feature3text: 'masters in finance according to the <i>Financial Time</i> are 100% French',
    },
    b06: {
      title1: 'A LEADING NATION',
      title2: 'FOR RESEARCH AND INNOVATION',
      feature1: '2<sup>nd</sup>',
      feature1b: '<b>most innovative<br>European country</b><br><span class="tiny">(European Patent Office)</span>',
      feature2: '2<sup>nd</sup>',
      feature2b: 'in the world for Fields medals (mathematics)',
      feature3: '4<sup>th</sup>',
      feature3b: 'for Nobel Prizes',
      image: 'b06-labo.png',
    },
    b07: {
      title1: 'Education',
      title2: 'studies',
      title3: 'Reasonably-priced',
      euro: '€',
      desc1: 'In French public<br>universities, non-European student pays less than',
      price: '4 000 €',
      desc2: " a year for a master's degree, and European student pay almost nothing.",
      image1: 'b07-pig.png',
      image2: 'b07-pig2.png',
    },
    b08: {
      title1: 'Learn and earn a degree in one of the world\'s top',
      title2: 'economic',
      title3: 'powers',
    },
    b09: {
      title1: 'A LABEL THAT GUARANTEES',
      title2: 'THE QUALITY OF STUDENT',
      title3: 'SUPPORT SERVICES',
    },
    b10: {
      title: 'The French way of life',
      image1: 'b10-illus1.png',
      image2: 'b10-illus2.png',
      image3: 'b10-illus3.png',
      image4: 'b10-illus4.png',
      image5: 'b10-illus5.png',
      image6: 'b10-illus6.png',
    },
    b11: {
      title1: 'SKILLS',
      title2: '& FRIENDS',
      title3: '<span class="green">FOR LIFE</span>',
      subtitle: 'Discover the stories of students whose educational journey in France looks like yours',
    },
    b12: {
      title1: 'DISCOVER<br/>OTHER INSPIRING',
      title2: 'journeys',
    },
    b13: {
      title: 'DISCOVER WHICH MAJOR TO ATTEND IN FRANCE',
      cursus: [
        'Economics',
        'Arts',
        'Letters',
        'Physics',
        'Engineering',
        'Law',
        'Management',
        'Communication',
        'Marketing',
        'Agrifood',
        'Architecture',
        'Urban planning',
        'Design',
        'Fashion',
        'Biology',
        'Chemistry',
        'Journalism',
        'Computer science',
        'Mathematics',
        'Medicine',
        'Philosophy',
        'Education',
        'Sports',
        'Catering and Hotel management',
        'Logistics',
      ],
      cta: 'CONTACT OUR EXPERTS TO LEARN MORE',
    },
    nav: [
      { id: 0, name: 'intro' },
      { id: 1, name: 'reason1' },
      { id: 2, name: 'reason2' },
      { id: 3, name: 'reason3' },
      { id: 4, name: 'reason4' },
      { id: 5, name: 'reason5' },
      { id: 6, name: 'reason6' },
      { id: 7, name: 'reason7' },
      { id: 8, name: 'reason8' },
      { id: 9, name: 'reason9' },
      { id: 10, name: 'reason10' },
      { id: 11, name: 'similar profile' },
      { id: 12, name: 'other profiles' },
      { id: 13, name: 'cursus' },
    ],
    similarStudents: {
      default: {
        student1: 'Sothearath',
        student2: 'Claudia',
      },
      india: {
        student1: 'Sarah',
        student2: 'Pankaj',
      },
      indonesia: {
        student1: 'Junghwa',
        student2: 'Hariyo',
      },
      japan: {
        student1: 'Sothearath',
        student2: 'Sarah',
      },
      korea: {
        student1: 'Junghwa',
        student2: 'Sarah',
      },
      vietnam: {
        student1: 'Dang Duy Anh',
        student2: 'Sothearath',
      },
      germany: {
        student1: 'Niels',
        student2: 'Alicia',
      },
      england: {
        student1: 'Alyssa',
        student2: 'Angelo',
      },
      nigeria: {
        student1: 'Claudia',
        student2: 'Sandra',
      },
      rwanda: {
        student1: 'Lucas',
        student2: 'Tahina',
      },
      ghana: {
        student1: 'Claudia',
        student2: 'Tchailga',
      },
      colombia: {
        student1: 'Valentina',
        student2: 'Luis',
      },
      mexico: {
        student1: 'Veronica',
        student2: 'Luis',
      },
      brazil: {
        student1: 'Luis',
        student2: 'Isabelle',
      },
      saudiarabia: {
        student1: 'Turki Ayed',
        student2: 'Moza',
      },
    },
    students: [
      {
        name: 'Pankaj',
        country: 'India',
        specialty: 'Independant consultant',
        desc: "Pankaj left India and laid down his luggage in France for his graduate studies. He has no regrets as the founder of several start-ups at the forefront of innovation and independent facilitation in the top French management schools. In sum, he is an accomplished businessman!",
        building: 'PhD in international Business',
        descBuilding: 'Montpellier Business School',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'pankaj-popup.png',
        bigpic: 'pankaj-slide.jpg',
        similar_bg: 'pankaj-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Sarah',
        country: 'South Korea',
        specialty: 'Luxury marketing expert',
        desc: "Paris is the capital of fashion and creativity. Above all, it is the capital of luxury. This is the main reason why Sarah left her home in Seoul to move to the City of Lights. There was no better place in the world to learn about luxury marketing.",
        building: 'Luxury management',
        descBuilding: 'Sciences Po Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'yellow',
        pic: 'sarah-popup.png',
        bigpic: 'sarah-slide.jpg',
        similar_bg: 'sarah-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Hariyo',
        country: 'Indonesia',
        desc: "This former gastronomy student became enthralled with French vineyards and decided to undertake a Professional Certificate in Agricultural Business Management at Beaune. Today, he is the first Indonesian winemaker in Bourgogne and manages the Domaine de la Main d'Or.",
        specialty: 'Indonesia Wine Grocer',
        building: 'Professional training in viticulture and oenology',
        descBuilding: '',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'hariyo-popup.png',
        bigpic: 'hariyo-slide.jpg',
        similar_bg: 'hariyo-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Junghwa',
        country: 'South Korea',
        specialty: 'Aeronautical engineer',
        desc: "After finishing the first year of her Research Master's in mechanical engineering in Korea, Junghwa decided to pursue her studies in France where she achieved brilliant success in a new master's in Aeronautical Research. Today she is a member of the EM2C laboratory.",
        building: 'PhD in EM2C (Énergétique Moléculaire et Macroscopique, Combustion)',
        descBuilding: 'University of Paris Saclay',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'junghwa-popup.png',
        bigpic: 'junghwa-slide.jpg',
        similar_bg: 'junghwa-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Sothearath',
        country: 'Cambodia',
        specialty: 'Project manager',
        desc: "A young doctor in economics whose work focused on local currencies, he is currently an energy consultant in Cambodia.",
        building: 'PhD in Economics',
        descBuilding: "University of Côte d'Azur",
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'sothearath-popup.png',
        bigpic: 'b12-sothearath.jpg',
        similar_bg: 'sothearath-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Dang Duy Anh',
        country: 'Vietnam',
        specialty: 'Development economist',
        desc: "Laureate of the Make Our Planet Great Again program in 2018, this young Vietnamese man uprooted his life in Hanoi to fly to Paris.",
        building: 'PhD in Economics',
        descBuilding: 'Paris School of Economics',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'dang-popup.png',
        bigpic: 'dang-slide.jpg',
        similar_bg: 'dang-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Niels',
        country: 'Germany',
        specialty: 'Climate change student-researcher',
        desc: "Recipient of the MOPGA scholarship program in 2018, Niels is now a PhD student at Claude Bernard University Lyon 1, participating in researchs to fight against global warming.",
        building: '',
        descBuilding: 'University of Claude Bernard Lyon 1',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'niels-popup.png',
        bigpic: 'niels-slide.jpg',
        similar_bg: 'niels-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Alicia',
        country: 'Spain',
        specialty: 'Conference interpreter and translator',
        desc: "With a Master's 2 in Conference Interpreting from Strasbourg, she works as a translator in the transport and engineering sectors.",
        building: '',
        descBuilding: 'University of Strasbourg',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'alicia-popup.png',
        bigpic: 'b12-alicia.jpg',
        similar_bg: 'alicia-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Alyssa',
        country: 'Australia',
        specialty: 'International communication officer',
        desc: "As part of her international studies in Sydney, in Australia, Alyssa specialized in French and in history before completing an exchange program at Science Po Paris.",
        building: '',
        descBuilding: 'Sciences Po Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'alyssa-popup.png',
        bigpic: 'b12-alyssa.jpg',
        similar_bg: 'alyssa-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Angelo',
        country: 'Italy',
        specialty: 'Designer and photographer',
        desc: "After an academic exchange in Paris as part of a Master of European Design, he now works as a designer and photographer.",
        building: '',
        descBuilding: 'Ecole Nationale Supérieure de Création Industrielle (ENSCI)',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'angelo-popup-v2.png',
        bigpic: 'b12-angelo.jpg',
        similar_bg: 'angelo-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Sandra',
        country: 'Nigeria',
        specialty: 'Entrepreneur committed to education',
        desc: "After passing a degree in International Relations in London, Sandra chose to continue her studies in France, at the iaelyon School of Management, in a Master’s degree course in International Business Management.",
        building: 'PhD in International Business',
        descBuilding: 'IAE of Lyon',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'sandra-popup.png',
        bigpic: 'sandra-slide.jpg',
        similar_bg: 'sandra-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Claudia',
        country: 'Gabon',
        specialty: 'Student in business administration',
        desc: "Passionate about finance, she studies auditing at a business school in Lyon. Recipient from the National Scholarship Agency of Gabon (ANGB).",
        building: 'Emlyon Business School',
        descBuilding: 'Saint-Etienne',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'claudia-popup.png',
        bigpic: 'b12-claudia.jpg',
        similar_bg: 'claudia-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Lucas',
        country: 'Kenya',
        specialty: 'Doctor of art history an archeology',
        desc: "Passionate about the cultural ties between Kenya and France,he rencently graduated with a PhD in art history and archeology.",
        building: 'Master 1 and Master 2 in history, civilization and heritage, PhD in art history and archeology.',
        descBuilding: "University of Pau and Pays de l'Adour(UPPA)",
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'lucas-popup.png',
        bigpic: 'b12-lucas.jpg',
        similar_bg: 'lucas-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Tahina',
        country: 'Madagascar',
        specialty: 'Engineer-researcher on drones',
        desc: "Winner of the L'Oréal-Unesco For Women in Science Scholarship, Tahina chose to enrol in a PhD program at the University of Reunion Island's computer science and mathematics laboratory. She specialized in distributed artificial intelligence.",
        building: 'University of la Réunion (ESIROI)',
        descBuilding: 'Campus of Paris-Saclay',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'tahina-popup.png',
        bigpic: 'tahina-slide.jpg',
        similar_bg: 'tahina-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Tchailga',
        country: 'Ivory Coast',
        specialty: 'Project manager in Mauritania',
        desc: "After a Master's degree in development economics and humanitarian action, she works in humanitarian project management. ",
        building: ' Master 1 d’Economie et management public puis Master 2 Ingénierie du Développement et de l’Action Humanitaire',
        descBuilding: 'University of Lille',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'tchailga-popup.png',
        bigpic: 'b12-tchailga.jpg',
        similar_bg: 'tchailga-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Luis',
        country: 'Colombia',
        specialty: 'Data Manager',
        desc: "After engineering studies in Metz, Luis became an quality expert in the pharmaceutical industry.",
        building: 'PhD in Industrial Engineering at National School of Engineers',
        descBuilding: 'Metz',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'luis-popup.png',
        bigpic: 'b12-luis.jpg',
        similar_bg: 'luis-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Valentina',
        country: 'Argentina',
        specialty: 'International Development Manager of an engineering school',
        desc: "Recipient of the Eiffel scholarship, she is responsible for the EU4DUAL European alliance at ESTIA, a prestigious engineering school in the Pays Basque.",
        building: 'PhD at Sciences Po Bordeaux in International Affairs - Politics and development in Africa and in the countries of the South (today called « Risks and development in the South »)',
        descBuilding: '',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'valentina-popup.png',
        bigpic: 'b12-valentina.jpg',
        similar_bg: 'valentina-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Veronica',
        country: 'Mexico',
        specialty: 'Technology Project Manager',
        desc: "A former student of the Lycée Franco-Mexicain (AEFE) in Mexico City, Veronica’s decision to move to France came naturally. After a preparatory class in physics-technology and engineering sciences in Lyon, she entered Arts et Métiers Paris Tech engineering school.",
        building: '',
        descBuilding: 'Art et Métiers Paris Tech (ENSAM)',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'veronica-popup.png',
        bigpic: 'veronica-slide.jpg',
        similar_bg: 'veronica-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Isabelle',
        country: 'Brazil',
        specialty: 'Fashion designer',
        desc: "Five years after her arrival in Paris and her fashion brand created, the artist-designer opens the doors of her studio to us, located in the heart of the fashion capital.",
        building: 'MBA Luxury and Fashion Management<br>MBA International Management',
        descBuilding: 'Paris School of Business <br>ESGCI, Paris',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'isabelle-popup.png',
        bigpic: 'isabelle-slide.jpg',
        similar_bg: 'isabelle-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
      {
        name: 'Turki Ayed',
        country: 'Saudi Arabia',
        specialty: 'Food quality and distribution expert',
        desc: "Born and raised in the Arabian desert, Turki Ayed chose the city of Lille, in northern France, to study toward a degree in food quality and distribution. The challenges facing the agrifood industry are of major importance to him because of the industry's growth potential in his country and region.",
        building: 'DEUST Distribution et Qualité des Produits Alimentaires',
        descBuilding: 'University of Lille',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'bluelight',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'turki-ayed-popup.png',
        bigpic: 'turki-ayed-slide.jpg',
        similar_bg: 'turki-ayed-profil.png',
        macaron: 'pink',
        calc: 'yellow',
      },
      {
        name: 'Moza',
        country: 'Qatar',
        specialty: 'Doctor of Philosophy at AgroParis Tech Paris Saclay',
        desc: "She graduated with a Master of Science in Doha and is pursuing a PhD in the field of agri-food.",
        building: '',
        descBuilding: 'AgroParisTech Paris Saclay',
        cta: 'CONTACTEZ NOS EXPERTS POUR EN SAVOIR PLUS',
        bgcolor: 'green',
        btncolor: 'orange',
        illustrationColor: 'blue',
        pic: 'moza-popup.png',
        bigpic: 'b12-moza.jpg',
        similar_bg: 'moza-profil.png',
        macaron: 'orange',
        calc: 'blue',
      },
    ],
    socials: [
      {
        name: 'Facebook',
        url: 'https://fr-fr.facebook.com/CampusFranceParis/',
        image: 'facebook.png',
      },
      {
        name: 'France Alumni',
        url: 'https://www.francealumni.fr/fr',
        image: 'francealumni.png',
      },
      {
        name: 'Instagram',
        url: 'https://www.instagram.com/campus_france/',
        image: 'instagram.png',
      },
      {
        name: 'LinkedIn',
        url: 'https://fr.linkedin.com/company/campusfrance',
        image: 'linkedin.png',
      },
      {
        name: 'Twitter',
        url: 'https://twitter.com/campusfrance?lang=fr',
        image: 'twitter.png',
      },
      {
        name: 'Youtube',
        url: 'https://www.youtube.com/user/ParisCampusFrance',
        image: 'youtube.png',
      },
    ]
  },
};