import { useRef, useEffect } from 'react';
import { Typography, Stack, Box } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Star } from '../../res/star2.svg';

import { Reason5 } from './style';
import Anchor from '../../components/Anchor';

function B05teaching(props) {
  const { gsap, lang } = props;
  const ref = useRef(null);

  const base = data[lang].b05;

  useEffect(() => {
    const elt = ref.current;

    const scrollAnim = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: '33% top',
        end: '+=1500',
        scrub: 1,
        pin: true,
      }
    });

    scrollAnim
      .to(elt.querySelector('.anim-wrapper'), { x: '-100vw', duration: 3 })
      .to(elt.querySelector('.anim1'), { opacity: 1, duration: 1.5 }, 1.5)
      .to(elt.querySelector('.anim1b'), { opacity: 1, scale: 1, duration: 1.5 }, 1.5)
      .to(elt.querySelector('.anim1c'), { scale: 1.5, duration: 1.5 }, 1.5)
      .to(elt.querySelector('.anim1'), { opacity: 0, duration: 1.5 }, 3)
      .to(elt.querySelector('.anim1b'), { opacity: 0, scale: 0.5, duration: 1.5 }, 3)
      .to(elt.querySelector('.anim1c'), { scale: 1, duration: 1.5 }, 3)
      
      .to(elt.querySelector('.anim-wrapper'), { x: '-200vw', duration: 2 }, 3)
      .to(elt.querySelector('.anim2'), { opacity: 1, duration: 1 }, 4)
      .to(elt.querySelector('.anim2b'), { opacity: 1, scale: 1, duration: 1 }, 4)
      .to(elt.querySelector('.anim2c'), { scale: 1.5, duration: 1 }, 4)
      .to(elt.querySelector('.anim2'), { opacity: 0, duration: 1 }, 5)
      .to(elt.querySelector('.anim2b'), { opacity: 0, scale: 0.5, duration: 1 }, 5)
      .to(elt.querySelector('.anim2c'), { scale: 1, duration: 1 }, 5)
      
      .to(elt.querySelector('.anim-wrapper'), { x: '-300vw', duration: 2 }, 5)
      .to(elt.querySelector('.anim3'), { opacity: 1, duration: 1 }, 6)
      .to(elt.querySelector('.anim3b'), { opacity: 1, scale: 1, duration: 1 }, 6)
      .to(elt.querySelector('.anim3c'), { scale: 1.5, duration: 1 }, 6);

  }, [gsap]);

  return (
    <Reason5 ref={ref}>
      <Anchor id='5' />
      <Stack justifyContent='center' alignItems='center' className='title'>
        {lang === 'en' && <Typography variant='h3' sx={{color: 'white.main'}}>A</Typography>}
        <Typography variant='h3' className={lang === 'en' ? 'bluetitle' : 'title1'}>{parse(base.title1)}</Typography>
        <Typography variant='h3' className={lang === 'en' ? 'title1' : 'bluetitle'} >{parse(base.title2)}</Typography>
      </Stack>
      <Stack justifyContent='center' alignItems='center' className='content'>
        <Star className='star' />
        <Stack direction='row' className='wrapper anim-wrapper'>
          <Stack justifyContent='center' alignItems='center' className='feature anim1'>
            <Box className='feature-bg anim1c'></Box>
            <Typography variant='h1' className='feature-title'>{parse(base.feature1order)}</Typography>
            <Typography variant='h6' className='feature-desc anim1b'>{parse(base.feature1text)}</Typography>
          </Stack>
          <Stack justifyContent='center' alignItems='center' className='feature anim2'>
            <Box className='feature-bg anim2c'></Box>
            <Typography variant='h1' className='feature-title-2'>{parse(base.feature2order)}</Typography>
            <Typography variant='h6' className='feature-desc anim2b'>{parse(base.feature2text)}</Typography>
          </Stack>
          <Stack justifyContent='center' alignItems='center' className='feature anim3'>
            <Box className='feature-bg anim3c'></Box>
            <Typography variant='h1' className='feature-title'>{parse(base.feature3order)}</Typography>
            <Typography variant='h6' className='feature-desc anim3b'>{parse(base.feature3text)}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Reason5>
  );
}

export default B05teaching;