import { useRef, useEffect } from 'react';
import { Typography, Box } from '@mui/material'; 

import { data } from '../../utils/data';

import { ReactComponent as Stamp } from '../../res/stamp.svg';
import { ReactComponent as StampTower } from '../../res/stamp-tower.svg';

import Anchor from '../../components/Anchor';
import { Reason10 } from './style';

function B10art(props) {
  const { gsap, lang, baseUrl } = props;
  const ref = useRef(null);

  const base = data[lang].b10;
  const imgBase = `${baseUrl}/images/blocks/`;

  useEffect(() => {
    const elt = ref.current;
    const windowWidth = document.querySelector('#root').clientWidth;
    const length = windowWidth + elt.querySelector('.anim-title').clientWidth + 50;

    gsap.to(elt.querySelector('.pin-wrapper'), {
      scrollTrigger: {
        trigger: elt.querySelector('.pin-wrapper'),
        start: `top ${window.screen.height * 0.4}px`,
        endTrigger: elt,
        end: `bottom ${window.screen.height * 0.4}px`,
        scrub: 1,
        pin: true
      },
      x: -length,
    });

    gsap.from(elt.querySelector('.stamp'), {
      scrollTrigger: {
        trigger: elt.querySelector('.stamp'),
        start: 'top bottom', 
        end: 'top top',
        scrub: 1,
      },
      x: -50,
      y: 30,
    });

    gsap.from(elt.querySelector('.stamp-tower'), {
      scrollTrigger: {
        trigger: elt.querySelector('.stamp-tower'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: 110,
      y: -30,
    });

    gsap.from(elt.querySelector('.illus1'), {
      scrollTrigger: {
        trigger: elt.querySelector('.illus1'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: -80,
    });

    gsap.from(elt.querySelector('.illus2'), {
      scrollTrigger: {
        trigger: elt.querySelector('.illus2'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: 90,
    });

    gsap.from(elt.querySelector('.illus3'), {
      scrollTrigger: {
        trigger: elt.querySelector('.illus3'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: -100,
    });

    gsap.from(elt.querySelector('.illus4'), {
      scrollTrigger: {
        trigger: elt.querySelector('.illus4'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: 100,
    });

    gsap.from(elt.querySelector('.illus5'), {
      scrollTrigger: {
        trigger: elt.querySelector('.illus5'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: -70,
    });

    gsap.from(elt.querySelector('.illus6'), {
      scrollTrigger: {
        trigger: elt.querySelector('.illus6'),
        start: 'top bottom',
        end: 'top top',
        scrub: 1,
      },
      x: 70,
    });

  }, [ gsap ]);

  return (
    <Reason10 ref={ref}>
      <Anchor id='10' />
      <Box className='title anim-title pin-wrapper'>
        <Typography variant="h1" >{base.title}</Typography>
      </Box>
      <Box className='illustrations anim-illustrations'>
        <img alt='' className='illus illus1' src={`${imgBase}${base.image1}`} />
        <img alt='' className='illus illus2 small' src={`${imgBase}${base.image2}`} />
        <img alt='' className='illus illus3' src={`${imgBase}${base.image3}`} />
        <Stamp className='stamp' />
        <img alt='' className='illus illus4' src={`${imgBase}${base.image4}`} />
        <StampTower className='stamp-tower' />
        <img alt='' className='illus illus5' src={`${imgBase}${base.image5}`} />
        <img alt='' className='illus illus6' src={`${imgBase}${base.image6}`} />        
      </Box>
    </Reason10>
  );
}

export default B10art;