import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import { ThemeProvider as SCThemeProvider } from "styled-components";
import CssBaseline from '@mui/material/CssBaseline';

import './fonts/fonts.css';
import theme from './theme';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <SCThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </SCThemeProvider>
  </ThemeProvider>
);