import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason1 = styled(Box)(
  ({ theme }) => `
  position: relative;
  height: 100vh;
  background-color: ${theme.palette.yellow.main};
  z-index: 1;
  .wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 600px;
    width: 100%;
    height: 100%;
  }
  .content {
    position: relative;
  }
  .title {
    font-weight: 700;
  }
  .pinktitle, .salmontitle {
    font-family: 'vFraunces', serif;
    font-weight: 500;
    text-align: center;
    padding: ${theme.spacing(2)};
    color: ${theme.palette.white.main};
  }
  .pinktitle {
    background-color: ${theme.palette.pink.main};
    transform: rotate(-3deg);
  }
  .salmontitle {
    background-color: ${theme.palette.salmon.main};
    transform: rotate(4deg);
    margin-top: 8px;
  }
  .tower {
    position: absolute;
    bottom: 97%;
    left: 70%;
  }
  .palette {
    position: absolute;
    top: 93%;
    left: 10%;
  }
  img {
    position: absolute;
    object-fit: contain;
  }
  .image1 {top: 0; left: -10vw; width: min(50vw, 300px);}
  .image2 {top: 27vh; left: 40vw; width: min(30vw, 200px);}
  .image3 {top: 40vh; right: 10vw; width: min(40vw, 300px); z-index:2;}
  .image4 {top: 50vh; left: 10vw; width: min(40vw, 300px);}
  .image5 {top: 55vh; right: 30vw; width: min(40vw, 300px); z-index:2;}
  .image6 {top: 65vh; right: 10vw; width: min(30vw, 200px);}
`);