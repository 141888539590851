import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason3 = styled(Box)(
  ({ theme }) => `
  // height: 100vh;
  background-color:  ${theme.palette.blue.main};
  margin-top: -25vh;
  padding-bottom: 25vh;
  .wrapper {
    max-width: 600px;
    overflow: hidden;
    position: relative;
    height: 40vh;
    width: 100%;
    display: grid;
    place-content: center;
    margin: auto;
  }
  .title {
    position: relative;
    color: ${theme.palette.white.main};
  }
  .title1 {
    font-family: 'vFraunces', sans-serif;
    font-style: italic;
    font-weight: 100;
  }
  .title3 {
    width: fit-content;
    padding: ${theme.spacing(1)};
    margin-left: ${theme.spacing(2)};
    background-color: ${theme.palette.orange.main};
    transform: rotate(-3deg);
  }
  .title4 {
    width: fit-content;
    padding: ${theme.spacing(1)};
    background-color: ${theme.palette.teal.main};
    border-radius: 50%;
  }
  .inline {
    display: inline-block;
  }
  .train {
    position: absolute;
    height: 50px;
    bottom: 0%;
    left: calc(100% - 50px);
  }
  .travels {
    color: ${theme.palette.yellow.main};
    max-width: 600px;
    overflow: hidden;
    margin: auto;
  }
  .travel-wrapper {
    position: relative;
    max-width: 600px;
    overflow: hidden;
    display: flex;
    width: 100%;
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(1)};
    padding-left: ${theme.spacing(2)};
    border-bottom: solid 1px ${theme.palette.yellow.main};
  }
  .travel-wrapper:first-child {
    border-top: solid 1px ${theme.palette.yellow.main};
  }
  .travel {
    width: fit-content;
  }
`);