import { useState } from 'react';
import { Typography, Box } from '@mui/material';
import React from "react";

import { data } from '../../utils/data';
import parse from '../../utils/parse';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from "swiper";

import 'swiper/css';

import ZoomStudent from '../../components/ZoomStudent';
import Anchor from '../../components/Anchor';
import { Presentation12 } from './style';

function B12otherprofile(props) {
  const [openStudents, setOpenStudents] = useState([]);

  const base = data[props.lang].b12;
  const students = data[props.lang].students;
  const imgBase = `${props.baseUrl}/images/students/`;

  const handleClickOpenStudents = idx => {
    let newOpenStudents = [...openStudents];
    newOpenStudents[idx] = true;
    setOpenStudents(newOpenStudents);
  };

  const handleCloseStudents = idx => {
    let newOpenStudents = [...openStudents];
    newOpenStudents[idx] = false;
    setOpenStudents(newOpenStudents);
  };

  return (
    <Presentation12>
      <Anchor id='12' />
      <Typography variant='h4' className='title1'>{parse(base.title1)}</Typography>
      <Typography variant='h3' className='title2'>{parse(base.title2)}</Typography>
      <Box className='wrapper' sx={{ width: ['100%', '100%', '50%'], margin: 'auto' }}>
        <Swiper
          slidesPerView={2}
          spaceBetween={1}
          freeMode={true}
          loop={true}
          modules={[FreeMode, Pagination]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }}
        >
          {students.map((student, idx) => (
            <SwiperSlide className="slide" key={idx}>
              <Box className='student' onClick={() => handleClickOpenStudents(idx)}>
                <img className='bigpic' alt='' src={`${imgBase}${student.bigpic}`} />
                <Box className='info'>
                  <Typography variant='body2' className='name'>{`${parse(student.name)}, ${parse(student.country)}`}</Typography>
                </Box>
              </Box>
              <ZoomStudent
                student={student}
                open={!!openStudents[idx]}
                handleClose={() => handleCloseStudents(idx)}
                baseUrl={props.baseUrl}
                lang={props.lang}
                regionUrl={props.regionUrl}
                region={props.region}>
              </ZoomStudent>
            </SwiperSlide>
          ))}          
        </Swiper>
      </Box>
    </Presentation12>
  );
}

export default B12otherprofile;