import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason8 = styled(Box)(
  ({ theme }) => `
  height: 100vh;
  padding: ${theme.spacing(6)};
  background-color: ${theme.palette.yellow.main};
  position: relative;

  .wrapper{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 220px;
  }

  .title1 {
    text-align: center;
    font-size: 2rem;
    line-height: 40px;
    font-weight: 600;
  }
  .cadre {
    text-align: center;
    font-size: 2rem;
    color: ${theme.palette.white.main};
    font-family: vFraunces;
    font-weight: 300;
  }
  .title2 {
    padding: 5px 10px;
    background-color: ${theme.palette.green.main};
    rotate: -5deg;
  }
  .title3 {
    padding: 5px 15px;
    background-color: ${theme.palette.blue.light};
    rotate: 1deg;
  }

  & .coins {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);
  }

  .fade {
    width: 100%;
    height: 10vh;
    position: absolute;
    background: red;
    left: 0;
    top: 0;
    z-index: 5; 
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 5%, ${theme.palette.yellow.main} 95%);
  }
`);