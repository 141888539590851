import { Box, Typography } from '@mui/material';
import { data } from '../../utils/data';
import { Reason7 } from './style';
import Anchor from '../../components/Anchor';
import { useEffect, useRef } from 'react';

import { ReactComponent as Coin } from '../../res/coin.svg';
import parse from '../../utils/parse';

function B07cost(props) {
  const { gsap, baseUrl } = props;
  const base = data[props.lang].b07;
  const reason7 = useRef(null);
  const pig = useRef(null);

  const imgBase = `${baseUrl}/images/blocks/`;


  useEffect(() => {
    const elt = reason7.current;
    const eltpig = pig.current;

    const labelAnim = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top 75%',
        end: 'bottom 75%',
        scrub: 1,
      }
    });

    labelAnim.to(elt, { backgroundColor: '#ffdf00'}, 0)
      .to(elt.querySelector('.euro'), { color: '#ffe73e', ease: 'none' }, 0)
      .to(elt.querySelector('.euro'), { fontWeight: '100', ease: 'none' }, 0)
      .to(elt.querySelector('.title3'), { fontWeight: '100' }, 0);

    const pigAnim = gsap.timeline({
      scrollTrigger: {
        trigger: eltpig,
        start: 'top bottom',
        end: '+=500',
        scrub: 1,
      }
    });

    pigAnim.from(eltpig.querySelector('.coin1'), { x: 15, y: -100, duration: 2 }, 1)
      .from(eltpig.querySelector('.coin2'), { x: 20, y: -150, duration: 1 }, 1.5)
      .from(eltpig.querySelector('.coin3'), { x: 25, y: -180, duration: 1 }, 2.5)
      .from(eltpig.querySelector('.coin4'), { x: 30, y: -75, duration: 1 }, 3.5)
      .from(eltpig.querySelector('.coin5'), { x: -10, y: -50, duration: 1 }, 1.5)
      .from(eltpig.querySelector('.coin6'), { x: 0, y: -148, duration: 1 }, 3.5)

  }, [gsap]);

  return (
    <Reason7>
      <Box ref={reason7} className='first-container'>
        <Anchor id='7' />
        <Typography variant="body1" className="euro">{base.euro}</Typography>
        <Box className="title1-wrapper">
          <Box className='title1-left-circle'></Box>
          <Box className='title1-right-circle'></Box>
          <Typography variant="body1" className="title1" >{base.title1}</Typography>
        </Box>
        <Typography textTransform="uppercase" variant="body1" className="title3">{base.title3}</Typography>
      </Box>
      <Box ref={pig} className='second-container'>
        <Typography className='desc' sx={{ textAlign: { xs: 'left', md: 'center' }, marginBottom: { xs: 0, md: 2 } }}>{parse(base.desc1)}</Typography>
        <Typography className='price'>{base.price}</Typography>
        <Typography className='desc' sx={{ textAlign: { xs: 'left', md: 'center' }, marginTop: { xs: 0, md: 2 } }}>{base.desc2}</Typography>
        <img alt='' className='pig' src={`${imgBase}${base.image1}`} />
        <img alt='' className='pig pig2' src={`${imgBase}${base.image2}`} />
        <Coin className='coin1' />
        <Coin className='coin2' />
        <Coin className='coin3' />
        <Coin className='coin4' />
        <Coin className='coin5' />
        <Coin className='coin6' />
      </Box>
    </Reason7>
  );
}

export default B07cost;