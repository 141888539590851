import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason7 = styled(Box)(
  ({ theme }) => `
  background-color: ${theme.palette.pink.main};
  
  .first-container {
    padding: ${theme.spacing(6)};
    position: relative;
    height: 100vh;
  }

  .title1-wrapper{
    position: absolute;
    bottom: 65%;
    left: 25%;
    transform: rotate(2deg);
    padding: 10px 2px;
    background-color: ${theme.palette.orange.light};
     ${theme.breakpoints.up(750)} {
        left: 35%;
      }
  }
  .title1-left-circle {
    height: 41px;
    width: 19px;
    background-color: ${theme.palette.orange.light};
    position: absolute;
    left: -18px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 25px 0 0 25px;
  }
  .title1-right-circle {
    height: 41px;
    width: 19px;
    background-color: ${theme.palette.orange.light};
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 25px 25px 0;
  }
  .title1 {
    color: ${theme.palette.white.main};
    width: fit-content;
    font-size: 1.7rem;
  }
  
  .title3 {
    color: ${theme.palette.white.main};
    position: relative;
    text-align: center;
    font-family: vFraunces;
    font-size: 3.3rem;
    font-weight: 900;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);

  }

  .euro {
    font-family: vFraunces;
    color: ${theme.palette.pink.light};
    font-size: 40rem;
    line-height: 30rem;
    font-weight: 900;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    left: -5%;
     ${theme.breakpoints.up(750)} {
        left: 20%;
      }
  }

  .second-container {
    position: relative;
    padding: 0 ${theme.spacing(6)};
    background-color: ${theme.palette.yellow.main};
    height: 50vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }

  .desc{
    width: 80%;
  }

  .price {
    width: fit-content;
    font-family: vFraunces;
    color: ${theme.palette.green.dark};
    background-color: ${theme.palette.green.main};
    font-size: 3.5rem;
    font-weight: 900;
    padding: 0 20px;
    margin: 10px 0;
    rotate: -5deg;
  }
  .coin1 {
    position: absolute;
    bottom: calc(67% + 70px);
    left: calc(65% + 75px);
    z-index: 9;
  }
  .coin2 {
    position: absolute;
    bottom: calc(67% + 70px);
    left: calc(65% + 75px);
    z-index: 9;
  }
  .coin3 {
    position: absolute;
    bottom: calc(67% + 70px);
    left: calc(65% + 75px);
    z-index: 9;
  }
  .coin4 {
    position: absolute;
    bottom: calc(67% + 70px);
    left: calc(65% + 75px);
    z-index: 9;
  }
  .coin5 {
    position: absolute;
    bottom: calc(67% + 70px);
    left: calc(65% + 75px);
    z-index: 9;
  }
  .coin6 {
    position: absolute;
    bottom: calc(67% + 70px);
    left: calc(65% + 75px);
    z-index: 9;
  }
  .pig {
    height: 140px;
    position: absolute;
    bottom: 70%;
    left: 65%;
    z-index: 8;
  }
  .pig2 {
    z-index: 10;
  }
`);