import { Box } from '@mui/material';
import styled from 'styled-components';

export const Presentation12 = styled(Box)(
  ({ theme }) => `
  min-height: 100vh;
  position: relative;
  z-index: 1;
  background-color: ${theme.palette.yellow.main};
  padding-top: ${theme.spacing(8)};
  .title1 {
    color: ${theme.palette.red.main};
    text-align: center;
  }
  .title2 {
    width: fit-content;
    margin: auto;
    margin-top: ${theme.spacing(1)};
    font-family: 'vFraunces';
    padding: ${theme.spacing(2)} ${theme.spacing(6)};
    border-radius: 20px;
    color: ${theme.palette.white.main};
    background-color: ${theme.palette.orange.main};
    text-align: center;
  }
  .wrapper {
    padding-top: ${theme.spacing(12)};
  }
  .students {
    position: relative;
    z-index: 10;
  }
  .student{
    aspect-ratio: 414/582;
    width: 142px;
  }
  .bigpic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;
  }
  .info {
    position: absolute;
    width: 142px;
    bottom: 20px;
  }
  .specialty{
    font-weight: 900;
    color: ${theme.palette.white.main};
    text-align: center;
    letter-spacing: 1px;
  }
  .name {
    font-weight: 300;
    text-align: center;
    text-transform: capitalize;
    color: ${theme.palette.white.main};
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    padding: 4px 0;
  }
`);