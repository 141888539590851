import { useRef, useEffect } from 'react';
import { Typography, Stack, Box } from '@mui/material';

import parse from '../../utils/parse';
import { data } from '../../utils/data';

import { ReactComponent as Tower } from '../../res/eiffel-tower.svg';
import { ReactComponent as Palette } from '../../res/palette.svg';

import Anchor from '../../components/Anchor';
import { Reason1 } from './style';

function B01training(props) {
  const { gsap, lang, baseUrl } = props;
  const ref = useRef(null);

  const base = data[lang].b01;
  const imgBase = `${baseUrl}/images/blocks/`;

  useEffect(() => {
    const elt = ref.current;
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top center',
        end: 'bottom bottom',
        scrub: true,
      }
    });
  
    tl.to(elt.querySelector('.anim-pic-top-left'), { y: '-5vh', x: '-10vw', ease: 'linear'},0);
    tl.to(elt.querySelector('.anim-pic-top-center'), { y: '-20vh', x: '2vw', ease: 'linear' }, 0);
    tl.to(elt.querySelector('.anim-pic-top-right'), { y: '-20vh', x: '40vw', ease: 'linear' }, 0);
    tl.to(elt.querySelector('.anim-pic-bottom-left'), { y: '18vh', x: '-20vw', ease: 'linear' }, 0);
    tl.to(elt.querySelector('.anim-pic-bottom-center'), { y: '15vh', x: '3vw', ease: 'linear' }, 0);
    tl.to(elt.querySelector('.anim-pic-bottom-right'), { y: '5vh', x: '20vw', ease: 'linear' }, 0);
  }, [ gsap ]);

  return (
    <Reason1 ref={ref}>
      <Anchor id='1' />
      <Stack justifyContent='center' alignItems='center' className='wrapper'>
        <Box className='content'>
          <Typography variant='h2' className='title'>{parse(base.title1)}</Typography>
          <Typography variant='h2' className='pinktitle'>{parse(base.title2)}</Typography>
          <Typography variant='h2' className='salmontitle'>{parse(base.title3)}</Typography>
          <Tower className='tower' />
          <Palette className='palette' />
        </Box>
        <img alt='' className='image1 anim-pic-top-left' src={`${imgBase}${base.image1}`} />
        <img alt='' className='image2 anim-pic-top-center small' src={`${imgBase}${base.image2}`} />
        <img alt='' className='image3 anim-pic-top-right' src={`${imgBase}${base.image3}`} />
        <img alt='' className='image4 anim-pic-bottom-left' src={`${imgBase}${base.image4}`} />
        <img alt='' className='image5 anim-pic-bottom-center' src={`${imgBase}${base.image5}`} />
        <img alt='' className='image6 anim-pic-bottom-right small' src={`${imgBase}${base.image6}`} />
      </Stack>
    </Reason1>
  );
}

export default B01training;