import { Button } from '@mui/material';
import styled from 'styled-components';

export const StyledButton = styled(Button)(
  ({ theme }) => `
  flex-direction: column;
  width: min(35vw, 200px) !important;
  background-color: ${theme.palette.green.main} !important;
  padding: ${theme.spacing(1)} ${theme.spacing(2)} !important;
  border-radius: 50px !important;
`);