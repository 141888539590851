import { useRef, useEffect } from 'react';
import { Typography, Box } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Train } from '../../res/train.svg';

import Anchor from '../../components/Anchor';
import { Reason3 } from './style';

function B03english(props) {
  const { gsap, lang } = props;
  const ref = useRef(null);

  const base = data[lang].b03;
  
  useEffect(() => {
    const elt = ref.current;
    const startAnim = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top 75%',
        end: 'top top',
        scrub: 2,
      }
    });

    startAnim.from(elt.querySelector('.anim-title-from-right'), { opacity: 0, y: +100, x: +300, duration: 16, ease: 'linear' }, 0)
      .from(elt.querySelector('.anim-title-from-left'), { opacity: 0, y: -100, x: -300, duration: 16, ease: 'linear' }, 8)
      .to(elt.querySelector('.anim-train'), { x: '-150vw', duration: 80, ease: 'linear' }, 16)
      .from(elt.querySelector('.anim-travel1'), { x: '-100vw', duration: 30, ease: 'linear' }, 20)
      .from(elt.querySelector('.anim-travel2'), { x: '100vw', duration: 30, delay: 1.5, ease: 'linear' }, 16)
      .from(elt.querySelector('.anim-travel3'), { x: '-100vw', duration: 30, delay: 3, ease: 'linear' }, 24)
      .from(elt.querySelector('.anim-travel4'), { x: '100vw', duration: 30, delay: 4.5, ease: 'linear' }, 16)

  }, [ gsap ]);

  return (
    <Reason3 ref={ref}>
      <Anchor id='3' />
      <Box className='wrapper'>
        <Box className='title'>
          <Typography variant='h4' className='title1'>{parse(base.title1)}</Typography>
          <Typography variant='h4' className='title1 inline'>{parse(base.title2)}</Typography>
          <Typography variant='h4' className='title3 inline anim-title-from-right'>{parse(base.title3)}</Typography>
          <Typography variant='h4' className='title4 anim-title-from-left'>{parse(base.title4)}</Typography>
        </Box>
        <Train className='train anim-train'/>        
      </Box>
      <Box className='travels'>
        <Box className='travel-wrapper'>
          <Typography variant='body1' className='travel anim-travel1'>{parse(base.travel1)}</Typography>
        </Box>
        <Box className='travel-wrapper'>
          <Typography variant='body1' className='travel anim-travel2'>{parse(base.travel2)}</Typography>
        </Box>
        <Box className='travel-wrapper'>
          <Typography variant='body1' className='travel anim-travel3'>{parse(base.travel3)}</Typography>
        </Box>
        <Box className='travel-wrapper'>
          <Typography variant='body1' className='travel anim-travel4'>{parse(base.travel4)}</Typography>
        </Box>
      </Box>
    </Reason3>
  );
}

export default B03english;