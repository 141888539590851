import { createTheme } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';

const primary = '#0747a6';

const Theme = {
  palette: {
    // Standard colors
    primary: {
      main: primary,
      light: '#0656cc',
      dark: '#043884',
      contrastText: '#fdfdfd',
    },
    secondary: {
      main: '#f2b41b',
      light: '#f6cd65',
      dark: '#e7aa0d',
      contrastText: '#fdfdfd',
    },
    error: {
      main: '#d64045',
      contrastText: "#fff",
    },
    warning: {
      main: '#f2b41b',
      contrastText: "#fff",
    },
    success: {
      main: '#5fab80',
      contrastText: "#fff",
    },
    white: {
      main: '#fff',
      contrastText: '#000',
    },
    black: {
      main: '#000',
      text: '#202020',
      light: '#666666',
      contrastText: '#fff',
    },
    grey: {
      main: '#d6d6d6',
      light: '#f2f2f2',
      dark: '#b8b8b8',
      contrastText: '#000',
    },
    // Specific colors
    blue: {
      main: '#000091',
      light: '#0292d3',
      contrastText: '#fff',
    },
    green: {
      main: '#4bb58f',
      dark: '#396664',
      contrastText: '#fff',
    },
    orange: {
      main: '#ff8c32',
      light: '#ffb400',
      contrastText: '#000',
    },
    pink: {
      main: '#ff5a3c',
      light: '#ff755b',
      contrastText: '#000',
    },
    red: {
      main: '#c10046',
      contrastText: '#000',
    },
    salmon: {
      main: '#ffb887',
      contrastText: '#000',
    },
    teal: {
      main: '#4bb58f',
      contrastText: '#000',
    },
    yellow: {
      main: '#ffdf00',
      light: '#ffe73e',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: '"Silka", arial, sans-serif',
    htmlFontSize: 16,
    h1: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '3rem',
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: 0.84,
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '2.5rem',
      fontWeight: 750,
      letterSpacing: 0,
      lineHeight: 1.07,
    },
    h3: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '2rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.23,
    },
    h4: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '1.6rem',
      fontWeight: 500,
      letterSpacing: '0.02375rem',
      lineHeight: 1.4,
    },
    h5: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '1.4rem',
      fontWeight: 500,
      letterSpacing: '0.02375rem',
      lineHeight: 1,
    },
    h6: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '1.2rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.25,
    },
    body1: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    body2: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '0.8rem',
      fontWeight: 100,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    subtitle1: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '1.15rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.4,
    },
    subtitle2: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '1rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.35,
    },
    button: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0.05rem',
      lineHeight: 2,
      textalign: 'left',
      textTransform: 'none',
    },
    overline: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '0.69rem',
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.27,
    },
    caption: {
      fontFamily: '"Silka", arial, sans-serif',
      fontSize: '0.9rem',
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: 1.2857,
    },
  },
  fontSize: {
    xsmall: {
      fontSize: '0.8rem',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflowX: 'hidden',
        }
      }
    }
  }
};

export default createTheme(Theme, frFR);
