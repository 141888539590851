import { useRef, useEffect } from 'react';
import { Typography, Box, Stack, Link } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Star } from '../../res/star2.svg';
import { ReactComponent as Cursor } from '../../res/cursor.svg';

import { Cursus13 } from './style';
import Anchor from '../../components/Anchor';

function B13cursus(props) {
  const { gsap, lang, regionUrl } = props;
  const ref = useRef(null); 

  const base = data[lang].b13;

  useEffect(() => {
    const elt = ref.current;

    gsap.to(elt.querySelector('.anim-star'), { rotate: 360, duration: 40, repeat: -1 });

  }, [ gsap ]);    

  return (
    <Cursus13 ref={ref}>
      <Anchor id='13' />
      <Box className='content'>
        <Box className='title'>
          <Typography variant="body2" className='sticky'>{base.title}</Typography>
        </Box>
        <Box className='cursus'>
          <Box className='wrapper anim-wrapper'>
            {base.cursus.map((specialty, idx) => (
              <Box key={idx} className='item'>{parse(specialty)}</Box>
            ))}            
          </Box>
        </Box>
      </Box>
      <Link href={regionUrl} className='link' target="_blank">
        <Stack justifyContent='center' alignItems='center' className='cta'>
          <Typography variant="body1" className='cta-content'>{base.cta}</Typography>
          <Star className='star anim-star' />
          <Cursor className='cursor' />
        </Stack>      
      </Link>
    </Cursus13>
  );
}

export default B13cursus;