import { Footer14 } from './style';
import { Stack, Link } from '@mui/material';

import { data } from '../../utils/data';

import { ReactComponent as Logo } from '../../res/campus-logo.svg';
import Anchor from '../../components/Anchor';

function B14footer(props) {
  const { baseUrl, lang } = props;

  const base = data[lang];
  const imgBase = `${baseUrl}/images/common/`;

  return (
    <Footer14 alignItems='center'>
      <Anchor id='14' />
      <Stack justifyContent="center" direction="row" className='socials'>
        {base.socials.map((social, idx) => (
          <Link key={idx} href={social.url} className='social' target="_blank">
            <img alt='' src={`${imgBase}${social.image}`} />
          </Link>
        ))}         
      </Stack>     
      <Logo className='logo' />
    </Footer14>
  );
}

export default B14footer;