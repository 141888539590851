import { Box } from '@mui/material';
import styled from 'styled-components';

export const Intro = styled(Box)(
  ({ theme }) => `
  position: relative;
  z-index: -1;
  height: 130vh;
  background-color: ${theme.palette.yellow.main};

  .container {
    position: relative;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
  }

  .barcode, .hat, .plane, .logo, .star, .target, .student1, .student2, .godown  {
    position: absolute;
  }

  .pinWrapper {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    z-index: 3;
  }

  .logo {
    position: absolute;
    height: 90px;
    width: 90px;
    left: 50%;
    top: 15px;
    transform: translateX(-50%);
    z-index: 1;
  }

  .title {
    position: absolute;
    top: calc( 50vh - 150px );
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
  }

  .pinktitle, .bluetitle {
    position: absolute;
    font-family: 'vFraunces', serif;
    font-style: italic;
    text-align: center;
    font-weight: 200;
    padding: ${theme.spacing(2)};
    color: ${theme.palette.white.main};
    z-index: 2;
  }
 
  .pinktitle {
    left: calc(50% - 115px);
    top: calc( 50vh - 60px );
    width: 230px;
    transform: translateX(-500px) translateY(-100px) rotate(-3deg);
    opacity: 0;

    background: 
      radial-gradient(circle 10px at top left , #0000 98% , ${theme.palette.pink.main}  ) top left,
      radial-gradient(circle 10px at top right , #0000 98% , ${theme.palette.pink.main}  ) top right,
      radial-gradient(circle 10px at bottom right , #0000 98% , ${theme.palette.pink.main}  ) bottom right,
      radial-gradient(circle 10px at bottom left , #0000 98% , ${theme.palette.pink.main}  ) bottom left;
    background-size:51% 51%;
    background-repeat:no-repeat;
  }

  .bluetitle {
    background-color: ${theme.palette.blue.light};
    width: 253px;
    height: 75px;
    left: calc(50% - 140px);
    top: calc(50vh + 15px);
    transform: translateX(500px) translateY(100px);
    opacity: 0;
  }

  .barcode {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
  }

  
  .hat {
    top: calc( 50vh - 230px );
    left: 20px;
    transform-origin: bottom left;
    opacity: 0;
  }

  .plane {
    width: 200px;
    top: calc( 100vh - 150px );
    right: 0px;
    opacity: 0;
  }

  .godown {
    position: absolute;
    width: 20px;
    left: 50%;
    top: calc( 100vh - 100px );
    transform: translateX(-50%);
    animation: godown 4s ease-in-out infinite;
    z-index: 1;
    opacity: 1;
  }

  @keyframes godown{
    0 { transform: translate(-50%, 0); }
    30% { transform: translate(-50%, -30px); }
    50% { transform: translate(-50%, 0); }
    70% { transform: translate(-50%, -30px); }
    90% { transform: translate(-50%, 0); }
    100% { transform: translate(-50%, 0); }
  }

  @keyframes bounce{
    0 { transform: scale(1); }
    50% { transform: scale(0.9); }
    100% { transform: scale(1); }
  }

  .star {
    z-index: 1;
    height: 200px;
    width: 200px;
    top: 10vh;
    right: -70px;
    // transform: translateX(100px) scale(0);
    opacity: 0;
  }

  .student2 {
    z-index: 1;
    height: 200px;
    opacity: 1;
    top: 10vh;
    z-index: 2;
    right: -20px;
    opacity: 0;
    // transform: translateX(100px);
    ${theme.breakpoints.up('md')} {
      height: 300px;
      right: -40px;
    }
  }

  .target {
    z-index: 1;
    height: 200px;
    width: 200px;
    top: 70vh;
    // top: 150vh;
    left: -50px;
    animation: bounce 5s ease-in-out infinite;
    // transform: translateX(-100px) scale(0);
    opacity: 0;
  }

  .student1 {
    z-index: 1;
    height: 200px;
    opacity: 1;
    top: 70vh;
    // top: 150vh;
    z-index: 2;
    left: 0px;
    opacity: 0;
    // transform: translateX(-100px);
    ${theme.breakpoints.up('md')} {
      height: 300px;
      left: -20px;
    }
  }
`);
