import { Stack } from '@mui/material';
import styled from 'styled-components';

export const Reason9 = styled(Stack)(
  ({ theme }) => `
  position: relative;
  height: 100vh;
  background-color: ${theme.palette.blue.light};

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    transform: translate(-50%, -50%);
    text-align: center;
    color: ${theme.palette.white.main};
    opacity: 0; 
  }

  .label {
    width: 90vw;
    opacity: 0.2;
  }
`);