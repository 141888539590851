import { useRef, useEffect } from 'react';
import { Typography, Stack, Box } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Arrow } from '../../res/arrow.svg';
import { ReactComponent as Star } from '../../res/star3.svg';
import { ReactComponent as Sticker } from '../../res/sticker.svg';
import { ReactComponent as Atom } from '../../res/atom.svg';
import { ReactComponent as Pi } from '../../res/pi.svg';
import { ReactComponent as Hexa } from '../../res/hexa.svg';
import { ReactComponent as Light } from '../../res/light.svg';

import Anchor from '../../components/Anchor';
import { Reason6 } from './style';

function B06research(props) {
  const { gsap, lang, baseUrl } = props;
  const ref = useRef(null);    

  const base = data[lang].b06;
  const imgBase = `${baseUrl}/images/blocks/`;

  useEffect(() => {
    const elt = ref.current;

    const startAnim = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top top',
        end: '+=2000',
        pin: true,
        scrub: 1,
      }
    });

    gsap.to(elt.querySelector('.sticker'), { rotate: '360deg', repeat: -1, duration: 120, ease: 'none' });

    startAnim.from(elt.querySelector('.anim-arrow'), { y: 150, opacity: 0, duration: 1 }, 0.5)
      .from(elt.querySelector('.anim-star'), { y: -100, opacity: 0, duration: 1 }, 0.5)
      .from(elt.querySelector('.anim-text-top-left'), { opacity: 0, duration: 1 }, 2)
      .from(elt.querySelector('.anim-light'), { x: -100, y: 200, opacity: 0, duration: 1 }, 2)
      .from(elt.querySelector('.anim-hexa'), { scale: 0, opacity: 0, duration: 1 }, 2)
      .from(elt.querySelector('.anim-text-bottom-right'), { x: '60vw', opacity: 0, duration: 1 }, 3.5)
      .from(elt.querySelector('.anim-pi'), { x: -200, opacity: 0, duration: 1 }, 3.5)
      .from(elt.querySelector('.anim-img'), { scale: 0, opacity: 0, duration: 1 }, 3.5)
      .from(elt.querySelector('.anim-text-bottom-left'), { opacity: 0, duration: 1 }, 5)
      .from(elt.querySelector('.anim-atom'), { x: '-50vw', duration: 1 }, 5);

  }, [ gsap ]);    

  return (
    <Reason6 ref={ref}>
      <Anchor id='6' />
      <Stack justifyContent='center' alignItems='center' className='content'>
        <Sticker className='sticker' />
        <Arrow className='arrow anim-arrow' />
        <Star className='star anim-star' />
        <Light className='light anim-light' />
        <Box className='hexa anim-hexa'>
          <Hexa />
          <Typography variant='body2' className='ftitre ftitre1'>{parse(base.feature1)}</Typography>
        </Box>
        <Box className='title'>
          <Typography variant='h3' className='title1'>{parse(base.title1)}</Typography>
          <Typography variant='h3' className='title2'>{parse(base.title2)}</Typography>
        </Box>
        <Box className='ftext f1text anim-text-top-left'>
          <Typography sx={{lineHeight: '1.2'}} variant='body2' className='f1text2'>{parse(base.feature1b)}</Typography>
        </Box>
        <Box className='ftext f2text anim-text-bottom-right'>
          <Typography variant='body2' className='f2text1 ftitre'>{parse(base.feature2)}</Typography>
          <Typography variant='body2' className='f2text2'>{parse(base.feature2b)}</Typography>
        </Box>
        <Pi className='pi anim-pi' />
        <img alt='' className='img anim-img' src={`${imgBase}${base.image}`} />
        <Box className='f3text atom anim-atom'>
          <Atom  />
          <Typography variant='body2' className='ftitre'>{parse(base.feature3)}</Typography>
        </Box>
        <Box className='f1text3 f3text anim-text-bottom-left'>
          <Typography variant='body2'>{parse(base.feature3b)}</Typography>
        </Box>
      </Stack>
    </Reason6>
  );
}

export default B06research;
