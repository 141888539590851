import { StyledAnchor } from './style';

function Anchor(props) {

  return (
    <StyledAnchor id={props.id}></StyledAnchor>
  );
}

export default Anchor;
