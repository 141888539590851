import { Box } from '@mui/material';
import styled from 'styled-components';

export const Reason4 = styled(Box)(
  ({ theme }) => `
  height: 320vh;
  background-color: ${theme.palette.blue.main};
  padding: ${theme.spacing(4)};
  position: relative;
  
  .desc {
    width: 80vw;
    text-align: center;
    height: 450px;
    color: ${theme.palette.white.main};
    padding-bottom: ${theme.spacing(4)};
    margin: 0 auto;
  }

  .row {
    height: 2.8rem;
  }

  .word {
    height: 3rem;
  }

  .letter {
    display: inline-block;
    width: 6vw;
    font-size: 2rem;
  }

  .languages {
    position: absolute;
    width: min(80vw, 500px);
    height: 100vh;
    top: 100vh;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .container {
    position: relative;
    margin-top: 60px;
  }
  
  .wrapper {
    position: relative;
    background-color: white;
    text-align: center;
    border-radius: 15px;
    padding: 15px;

    .title {
      font-family: 'vFraunces', serif;
      font-size: 5rem;
      font-weight: 700;
    }

    .subtitle {
      color: ${theme.palette.blue.main};
    }
  }
  
  .wrapper-planet {
    z-index: 2;
    width: 80%;
    
    .title {
      color: ${theme.palette.blue.light};
    }
  }

  .wrapper-bag {
    width: 70%;
    margin-left: auto;

    .title {
      color: ${theme.palette.orange.main};
    }
  }

  .wrapper-cursor {
    width: 70%;
    margin-top: 100px;

    .title {
      color: ${theme.palette.pink.main};
    }
  }

  .planet {
    position: absolute;
    top: -60px;
    right: 0px;
    height: 110px;
  }

  .bag {
    position: absolute ;
    top: calc(100% - 80px);
    right: calc(100% - 30px);
    height: 110px;
  }

  .cursor {
    position: absolute;
    top: 40%;
    left: calc(100% - 60px);
    transform: translateY(-50%);
    height: 110px;
  }
`);