import { useRef, useEffect } from 'react';
import { Typography } from '@mui/material';

import { data } from '../../utils/data';
import parse from '../../utils/parse';

import { ReactComponent as Label } from '../../res/label.svg';

import Anchor from '../../components/Anchor';
import { Reason9 } from './style';

function B09label(props) {
  const { gsap, lang } = props;
  const reason9 = useRef(null);

  const base = data[lang].b09;

  useEffect(() => {
    const elt = reason9.current;

    const labelAnim = gsap.timeline({
      scrollTrigger: {
        trigger: elt,
        start: 'top top',
        end: '+=1500',
        pin: true,
        scrub: 1,
      }
    });
    labelAnim
      .to(elt.querySelector('.anim1'), { opacity: 1, duration: 4 }, 1)
      .to(elt.querySelector('.anim1'), { opacity: 0, duration: 1 })
      .to(elt.querySelector('.anim2'), { opacity: 1, duration: 4 })
      .to(elt.querySelector('.anim2'), { opacity: 0, duration: 1 })
      .to(elt.querySelector('.anim3'), { opacity: 1, duration: 4 })
      .to(elt.querySelector('.anim3'), { opacity: 0, duration: 1 })
      .from(elt.querySelector('.anim'), { scale: 0.4, duration: 15 }, 0);

    gsap.to(
      elt.querySelector('.anim'),
      {
        scrollTrigger: {
          trigger: elt,
          start: 'center 48%',
          end: 'bottom 40%',
          scrub: 1,
        },
        opacity: '1',
        ease: 'linear'
      }
    );      

  }, [gsap]);

  return (
    <Reason9 ref={reason9} justifyContent='center' alignItems='center'>
      <Anchor id='9' />
      <Typography variant='h3' className='title anim1'>{parse(base.title1)}</Typography>
      <Typography variant='h3' className='title anim2'>{parse(base.title2)}</Typography>
      <Typography variant='h3' className='title anim3'>{parse(base.title3)}</Typography>
      <Label className='label anim' />
    </Reason9>
  );
}

export default B09label;